'use strict';
app.factory("httpService", ['$rootScope', '$http', 'httpBase', '$compile', function ($rootScope, $http, httpBase, $compile) {
    //////////////////////////////NOTE/////////////////////////////
    //  List of available datasource in httpservice
    //   1.uom
    //   2.payterm
    //   3.ccid         (everything EXCLUDE costcentertype = FA)
    //   4.currinfo
    //   5.grniacc
    //   6.bank
    //   7.cash
    //   8.ou
    //   9.coa          (everything EXCLUDE costcentertype = FA)
    //  10.contact
    //  11.block
    //  12.activity
    //  13.company
    //  14.gang
    //  15.empy         (empypermas)
    //  16.paycode
    //  17.mill
    //  18.vehicle
    //  19.attdcode
    //  20.post
    //  21.div
    //  22.store
    //  23.itemcat
    //  24.itemmas
    //  25.plantsource
    //  26.nurbatch
    //  27.plantmate
    //  28.dept
    //  29.addrem
    //  30.taxded       (checkroll/payroll de, not account's gst!!)
    //  31.aprvlvl
    //  32.acccat
    //  33.fin          (finglconfg)
    //  34.tax          (gst tax)
    //  35.terrain
    //  36.soiltype
    //  37.uncularea
    //  38.bgtprep
    //  39.facoa        (everything INCLUDE costcentertype = FA)
    //  40.faccid       (everything INCLUDE costcentertype = FA)
    //  41.onlyfacoa    (ONLY costcentertype = FA)
    //  42.onlyfaccid   (ONLY costcentertype = FA)
    //  43.field
    //  44.stoconfg
    //  45.vegetype
    //  46.vege
    //  47.salesitem
    //  48.clientnotification
    //  49.wgitem
    //  50.type
    //  51.typeID
    // (39) and (40) special case, the result only contains FA info, hence it will concat with (9) and (3)

    //  good luck have fun

    //steps to add new datasources
    //1. DONT TOUCH ANYTHING IN "SPECIAL FUNCTION" !!!!!!
    //2. add data source structure into _DataSourceCollection
    //3. add queue into _queueDataSource
    //4. add accessor into _Accessor, refer to Me for naming convention, otherwise will fail
    //5. add in update function into GetData in CHATHUB
    //6. add in $rootScope.PropSigRUpdate("cash", e.response, e.type); in "requestEnd" function in the javascript controller of that dataSource.
    //7. add in UpdateDSColValidity.UpdateValidity(new string[] { "yourcustomupdateflag" }, userAccess); in post/delete/put functions in the c# controller of the dataSource.
    //8. cross your finger and hope that it works.

    //////////////////////////////////////////////////////////////SPECIAL FUNCTION////////////////////////////////////////////////////////////////////////////

    let httpServiceFactory = {};

    let _broadcast = function (event, data) {
        $rootScope.$broadcast(event, data);
    };

    let _PropSigRUpdate = function (type, rawresponse, updatetype) {
        if (updatetype === "read") {
            //completely ignore read
            return;
        }

        let col = (typeof _Accessor(type) === "string") ? _DataSourceCollection["Set" + _Accessor(type)].Model.id : "";
        let key = "";

        if (col !== "") {
            if (updatetype === "update") {
                if (typeof rawresponse !== "undefined" && typeof rawresponse[col] !== "undefined") {
                    key = rawresponse[col];
                }
                else {
                    key = "";
                    col = "";
                    updatetype = "";
                }
            }
            else if (updatetype === "insert" || updatetype === "create") {
                updatetype = "insert"

                if (typeof rawresponse !== "undefined" && typeof rawresponse[col] !== "undefined") {
                    key = rawresponse[col];
                }
                else {
                    key = "";
                    col = "";
                    updatetype = "";
                }
            }
            else if (updatetype === "destroy" || updatetype === "delete") {
                updatetype = "delete";

                if (typeof rawresponse !== "undefined" && typeof rawresponse[col] !== "undefined") {
                    key = rawresponse[col];
                }
                else {
                    key = "";
                    col = "";
                    updatetype = "";
                }
            }
            else {
                key = "";
                col = "";
                updatetype = "";
            }
        }
        else {
            key = "";
            col = "";
            updatetype = "";
        }

        httpBase._PropSigRUpdate(type, key, col, updatetype).then(function (result) {
            _updateDataSource(result);
        });
    };

    let _getModel = function (input) {
        let accessor = _Accessor(input);

        if (typeof accessor === "string")
            return _.cloneDeep(_DataSourceCollection["Set" + accessor].Model);
        else
            return {};
    };

    let _getSort = function (input) {
        let accessor = _Accessor(input);

        if (typeof accessor === "string")
            return _.cloneDeep(_DataSourceCollection["Set" + accessor].Sort);
        else
            return {};
    };

    let _GetDataSource = function (type, sorting, filter) {
        let _type = typeof type !== "string" ? "" : type.toLowerCase().trim();
        let _sorting = sorting;
        let _filter = filter;

        if (_type === "") {
            return new kendo.data.DataSource();
        }

        return kendo.data.DataSource.create({
            type: "odata",
            schema:
            {
                data: function (data) {
                    return data;
                },
                total: function (data) {
                    return data.length;
                },
                model: _getModel(_type)
            },
            pageSize: $rootScope.comboBoxPageSize,
            transport:
            {
                read: function (options) {
                    setTimeout(() => {
                        _FillDataSource(_type).then(function (data) {
                            if (typeof data.sortBy === "function" && (typeof _sorting !== "undefined" || typeof _getSort(_type) !== "undefined")) {
                                let currentSort = (typeof _sorting !== "undefined" ? _.cloneDeep(_sorting) : _.cloneDeep(_getSort(_type)));

                                if (typeof currentSort.length === "undefined") {
                                    options.success(currentSort.dir === "desc" ? data.sortBy("-" + currentSort.field) : data.sortBy(currentSort.field));
                                }
                                else {
                                    let sortArray = currentSort.map(x => x.dir == "desc" ? ("-" + x.field) : x.field);

                                    options.success(sortArray.length === 0 ? data : data.sortBy(...sortArray));
                                }
                            }
                            else {
                                options.success(data);
                            }
                        }).catch(function (err) {
                            options.error(err);
                        });
                    }, 0);
                }
            },
            sort: typeof _sorting !== "undefined" ? _sorting : (typeof _getSort(_type) !== "undefined" ? _.cloneDeep(_getSort(_type)) : void 0),
            filter: typeof _filter !== "undefined" ? _filter : void 0
        });
    };

    let _retrieveData = function (CurrentID, accessor, returnValue, db) {
        return new Promise(function (resolve, reject) {
            let CurrentReq = db.transaction([httpBase._IDBInfo.DataTableName], "readwrite").objectStore(httpBase._IDBInfo.DataTableName).get([accessor.toLowerCase(), CurrentID]);

            CurrentReq.onsuccess = function (e) {
                if (_queueDataSource["Queue" + accessor].length == 0 && e.target.result !== undefined) {
                    if ((returnValue.indexOf(accessor.toLowerCase()) == -1)) {
                        if (httpBase._checksum(e.target.result.dataString) === e.target.result.checksum && e.target.result.ID === CurrentID) {
                            _DataSourceCollection["Set" + accessor].Data = e.target.result.dataString;
                            _DataSourceCollection["Set" + accessor]["IsAvaData" + accessor] = true;
                            resolve();
                        }
                        else {
                            resolve();
                        }
                    }
                    else {
                        resolve();
                    }
                }
                else {
                    resolve();
                }
            };

            CurrentReq.onerror = function (e) {
                console.log(e);
                resolve();
            };
        });
    };

    let _cacheAll = function (getfromindexedDB) {
        return new Promise(function (resolve, reject) {
            _ForceReIniCollection();

            if (getfromindexedDB === true && httpBase._IDBInfo.enableIndexedDB === true) {
                httpBase._openDB().then(function (db) {
                    let typeDatePair = [];

                    let CurrentReq = db.transaction([httpBase._IDBInfo.DataTableName], "readwrite").objectStore(httpBase._IDBInfo.DataTableName).getAll();

                    CurrentReq.onsuccess = function (e) {
                        let len = e.target.result.length;
                        for (var i = 0; i < len; i++) {
                            typeDatePair.push({
                                Date: e.target.result[i].created,
                                Type: e.target.result[i].type
                            });
                        }

                        httpBase._getUIInfo(true).then(function (returnValue) {
                            let CurrentID = httpBase._getClientUserID();
                            let accessor = _Accessor();
                            let readreq = [];

                            for (var i = 0; i < accessor.length; i++) {
                                readreq.push(_retrieveData(CurrentID, accessor[i], returnValue, db));
                            }

                            Promise.all(readreq).then(function () {
                                resolve();
                            }, function () {
                                    reject();
                                });
                        }, function () {
                                //failed to open, fall back to old method
                                httpBase._IDBInfo.enableIndexedDB = false;
                                _cacheAll(void 0).then(function () {
                                    resolve();
                                }, function () {
                                        reject();
                                    });
                            });
                    }

                    CurrentReq.onerror = function (e) {
                        //failed to open, fall back to old method
                        httpBase._IDBInfo.enableIndexedDB = false;
                        _cacheAll(void 0).then(function () {
                            resolve();
                        }, function () {
                                reject();
                            });
                    };
                }, function () {
                        //failed to open, fall back to old method
                        httpBase._IDBInfo.enableIndexedDB = false;
                        _cacheAll(void 0).then(function () {
                            resolve();
                        }, function () {
                                reject();
                            });
                    });
            }
            else {
                resolve();
            }
        });
    };

    let _updateDataSourceByKey = function (accessor, rawresult, updatetype, form) {
        try {
            if (typeof rawresult === "string" && typeof updatetype === "string") {
                if (rawresult !== "" && updatetype !== "" && (updatetype === "update" || updatetype === "insert" || updatetype === "delete")) {
                    let result = JSON.parse(rawresult);

                    if (typeof result !== "undefined" && result !== null) {
                        if (typeof result.length !== "undefined") {
                            //if blank result then no idea which to update, force scrap entire datasource
                            if (result.length > 0) {
                                //parse decimal passed as string from server
                                result = httpBase._parseStringToDecimal(result);

                                //update only if it is available, if it is not available, ignore and let it to grab full set from server
                                if (_DataSourceCollection["Set" + accessor]["IsAvaData" + accessor] === true) {
                                    let originalSet = httpBase._decompress(_DataSourceCollection["Set" + accessor].Data);
                                    let col = _DataSourceCollection["Set" + accessor].Model.id;

                                    let map = {};
                                    for (var mind = 0; mind < result.length; mind++) {
                                        map[result[mind][col]] = 1;
                                    }

                                    originalSet = originalSet.filter(function (obj) {
                                        return !(obj[col] in map);
                                    });

                                    if (updatetype !== "delete") {
                                        originalSet = originalSet.concat(result);
                                    }

                                    //if still true
                                    if (_DataSourceCollection["Set" + accessor]["IsAvaData" + accessor] === true) {
                                        _DataSourceCollection["Set" + accessor].Data = httpBase._compress(originalSet);
                                        httpBase._entrytoDB(_DataSourceCollection["Set" + accessor].Data, form, httpBase._getClientUserID());
                                        return true;
                                    }

                                    originalSet = null;
                                    map = null;
                                }
                            }
                        }
                    }
                }
            }

            return false;
        }
        catch (ex) {
            return false;
        }
    }

    let _updateDataSource = function (result) {
        if (typeof result === "undefined" || typeof result.length === "undefined")
            return;

        for (var i = 0; i < result.length; i++) {
            let row = result[i];

            if (typeof row.type === "undefined" || typeof (_DataSourceCollection) === "undefined") {
                return;
            }

            if (row.type !== "") {
                //general case, update itself only
                let accessor = _Accessor(row.type);

                if (!_updateDataSourceByKey(accessor, row.result, row.updatetype, row.type)) {
                    _DataSourceCollection["Set" + accessor]["IsAvaData" + accessor] = false;
                    _DataSourceCollection["Set" + accessor].Data = "";
                }

                //force reload notification
                if (row.type == "clientnotification") {
                    _forcedReloadClient("clientnotification");
                    $rootScope.$broadcast('reloadNotification');
                }
            }
            else {
                _cacheAll(true);
            }
        }
    };

    let _FillDataSource = function (type) {
        return new Promise(function (resolve, reject) {
            let accessor = _Accessor(type)

            if (typeof accessor === "string") {
                //special case
                if (type === "facoa") {
                    _FillDataSource("coa").then(function (non_fa_acc) {
                        _FillDataSource("onlyfacoa").then(function (fa_acc) {
                            resolve((_.cloneDeep(non_fa_acc)).concat(_.cloneDeep(fa_acc)));
                        }, function (error) {
                                reject(error);
                            });
                    }, function (error) {
                            reject(error);
                        });
                }
                else if (type === "faccid") {
                    _FillDataSource("ccid").then(function (non_fa_ccid) {
                        _FillDataSource("onlyfaccid").then(function (fa_ccid) {
                            resolve((_.cloneDeep(non_fa_ccid)).concat(_.cloneDeep(fa_ccid)));
                        }, function (error) {
                                reject(error);
                            });
                    }, function (error) {
                            reject(error);
                        });
                }
                else {
                    let isSignalRValid = ($rootScope.getConnectionState("main") === $.signalR.connectionState.connected);

                    if (isSignalRValid === false
                        || _DataSourceCollection["Set" + accessor]["IsAvaData" + accessor] === false
                        || _DataSourceCollection["Set" + accessor]["IsAlwaysReload" + accessor] === true
                        || httpBase._isClientBlacklisted()) {
                        if (_queueDataSource["Queue" + accessor].length == 0)
                            _queueDataSource["Queue" + accessor].push($http(_DataSourceCollection["Set" + accessor].Http));

                        _queueDataSource["Queue" + accessor][0].then(function (result) {
                            let data =
                                (result.data.value !== undefined && result.data.value !== null)
                                    ? (result.data.value)
                                    : ((result.data !== undefined && result.data !== null)
                                        ? (result.data)
                                        : null);

                            if (data != null) {
                                httpBase._checkJSONAsync(data, true).then(function () {
                                    //parse decimal passed as string from server
                                    data = httpBase._parseStringToDecimal(data);

                                    httpBase._compressAsync(data).then(function (compressedData) {
                                        _DataSourceCollection["Set" + accessor].Data = compressedData;
                                        _DataSourceCollection["Set" + accessor]["IsAvaData" + accessor] = true;
                                        httpBase._entrytoDB(_DataSourceCollection["Set" + accessor].Data, type, httpBase._getClientUserID());

                                        resolve(_.cloneDeep(data));
                                        _queueDataSource["Queue" + accessor].shift();
                                    }, function (error) {
                                            //not valid response, reject!
                                            _DataSourceCollection["Set" + accessor]["IsAvaData" + accessor] = false;
                                            _DataSourceCollection["Set" + accessor].Data = "";
                                            reject(error);
                                            _queueDataSource["Queue" + accessor].shift();
                                        });
                                }, function () {
                                        //not valid response, reject!
                                        _DataSourceCollection["Set" + accessor]["IsAvaData" + accessor] = false;
                                        _DataSourceCollection["Set" + accessor].Data = "";
                                        reject();
                                        _queueDataSource["Queue" + accessor].shift();
                                    });
                            }
                            else {
                                //not valid response, reject!
                                _DataSourceCollection["Set" + accessor]["IsAvaData" + accessor] = false;
                                _DataSourceCollection["Set" + accessor].Data = "";
                                reject();
                                _queueDataSource["Queue" + accessor].shift();
                            }
                        }, function (error) {
                                _DataSourceCollection["Set" + accessor]["IsAvaData" + accessor] = false;
                                _DataSourceCollection["Set" + accessor].Data = "";
                                reject(error);
                                _queueDataSource["Queue" + accessor].shift();
                            });
                    }
                    else {
                        httpBase._decompressAsync(_DataSourceCollection["Set" + accessor].Data).then(function (decompressedData) {
                            resolve(decompressedData);
                        }, function (error) {
                                reject(error);
                            });
                    }
                }
            }
            else {
                resolve([]);
                //resolve an empty datasource so that it wont mess up the sequence
                console.log("Datasource(" + type + ") not available in list.");
            }
        });
    };

    let _ForceReIniCollection = function () {
        let accessor = _Accessor();

        if (typeof _DataSourceCollection != "undefined")
            for (var i = 0; i < accessor.length; i++) {
                _DataSourceCollection["Set" + accessor[i]]["IsAvaData" + accessor[i]] = false;
                _DataSourceCollection["Set" + accessor[i]].Data = "";
            }

        if (typeof _queueDataSource != "undefined")
            for (var i = 0; i < accessor.length; i++) {
                _queueDataSource["Queue" + accessor[i]] = [];
            }
    };

    let _forcedReloadClient = function (specificType) {
        let accessor1 = _Accessor(specificType);
        if (typeof accessor1 === "string") {
            if (_DataSourceCollection["Set" + accessor1]["IsAvaData" + accessor1] == true && _queueDataSource["Queue" + accessor1].length == 0) {
                _DataSourceCollection["Set" + accessor1]["IsAvaData" + accessor1] = false;
                _DataSourceCollection["Set" + accessor1].Data = "";
                _FillDataSource(accessor1.toLowerCase());
            }
        }
        else {
            for (var i = 0; i < accessor1.length; i++) {
                if (_DataSourceCollection["Set" + accessor1[i]]["IsAvaData" + accessor1[i]] == true && _queueDataSource["Queue" + accessor1[i]].length == 0) {
                    _DataSourceCollection["Set" + accessor1[i]]["IsAvaData" + accessor1[i]] = false;
                    _DataSourceCollection["Set" + accessor1[i]].Data = "";
                    _FillDataSource(accessor1[i].toLowerCase());
                }
            }
        }
    };

    let _dumpAll = function (specificType) {
        console.log('%cMemory issue will occur if you call this. Please use it for debugging purposes only!', 'background:red;color:#fff');

        if (httpBase._IDBInfo.enableIndexedDB === true) {
            httpBase._openDB().then(function (db) {
                let CurrentReq = db.transaction([httpBase._IDBInfo.DataTableName], "readwrite").objectStore(httpBase._IDBInfo.DataTableName).getAll();

                CurrentReq.onsuccess = function (e) {
                    let rawData = [];

                    let len = e.target.result.length;
                    for (var i = 0; i < len; i++) {
                        if (typeof specificType === "string") {
                            if (e.target.result[i].type === specificType) {
                                try {
                                    let test = (e.target.result[i].dataString === "" || e.target.result[i].dataString === undefined || e.target.result[i].dataString === null || e.target.result[i].dataString === []) ? [] : JSON.parse(e.target.result[i].dataString);
                                    test = null;
                                }
                                catch (err) {
                                    console.log('%ccorrupted data (' + e.target.result[i].type + ')!', 'background:red;color:#fff');
                                    return;
                                }

                                rawData.push({
                                    type: e.target.result[i].type,
                                    //dataString: httpBase._decompress(e.target.result[i].dataString),
                                    dataString: (e.target.result[i].dataString === "" || e.target.result[i].dataString === undefined || e.target.result[i].dataString === null || e.target.result[i].dataString === []) ? [] : JSON.parse(e.target.result[i].dataString),
                                    created: e.target.result[i].created,
                                    checksum: e.target.result[i].checksum,
                                    ID: e.target.result[i].ID
                                });
                            }
                        }
                        else {
                            try {
                                let test = (e.target.result[i].dataString === "" || e.target.result[i].dataString === undefined || e.target.result[i].dataString === null || e.target.result[i].dataString === []) ? [] : JSON.parse(e.target.result[i].dataString);
                                test = null;
                            }
                            catch (err) {
                                console.log('%ccorrupted data (' + e.target.result[i].type + ')!', 'background:red;color:#fff');
                                return;
                            }

                            rawData.push({
                                type: e.target.result[i].type,
                                //dataString: httpBase._decompress(e.target.result[i].dataString),
                                dataString: (e.target.result[i].dataString === "" || e.target.result[i].dataString === undefined || e.target.result[i].dataString === null || e.target.result[i].dataString === []) ? [] : JSON.parse(e.target.result[i].dataString),
                                created: e.target.result[i].created,
                                checksum: e.target.result[i].checksum,
                                ID: e.target.result[i].ID
                            });
                        }
                    }

                    console.log(rawData);
                }

                CurrentReq.onerror = function (e) {
                    console.log('%cfailed to dump!', 'background:red;color:#fff');
                };
            }, function (e) {
                    console.log('%cfailed to dump!', 'background:red;color:#fff');
                });
        }
        else {
            console.log('%cindexeddb is disabled!', 'background:red;color:#fff');
        }
    };

    let _extendKendoDatasource = (function _extendKendoDatasource() {
        if (typeof kendo !== "undefined") {
            kendo.ui.Grid.prototype.redrawRow = function (uid) {
                let grid = this;

                let colGroup = $('[data-uid="' + uid + '"]');

                if (colGroup.length == 1) {
                    //no frozen column
                    let dataItem = grid.dataItem(colGroup[0]);
                    let rowChildren = $(colGroup[0]).children('td[role="gridcell"]');

                    for (var i = 0; i < grid.columns.length; i++) {
                        var column = grid.columns[i];
                        var template = column.template;
                        var cell = rowChildren.eq(i);

                        if (template !== undefined)
                        {
                            //compile with kendo
                            var kendoTemplate = kendo.template(template);

                            if (template.contains("'checkbox'"))
                            {
                                //render the cell, special handle for angular event like ng-click
                                cell.html($compile(kendoTemplate(dataItem))(cell.scope().$parent));
                            }
                            else
                            {
                                //render the cell
                                cell.html(kendoTemplate(dataItem));
                            }
                        }
                        else {
                            var fieldValue = dataItem[column.field];

                            var format = column.format;
                            var values = column.values;

                            if (values !== undefined && values != null) {
                                // use the text value mappings (for enums)
                                for (var j = 0; j < values.length; j++) {
                                    var value = values[j];
                                    if (value.value == fieldValue) {
                                        cell.html(value.text);
                                        break;
                                    }
                                }
                            }
                            else if (format !== undefined) {
                                // use the format
                                cell.html(kendo.format(format, fieldValue));
                            }
                            else {
                                // Just dump the plain old value
                                cell.html(fieldValue);
                            }
                        }
                    }
                }
                else if (colGroup.length == 2) {
                    //there's frozen column
                    let frozenColCount = $(colGroup[0]).children('td[role="gridcell"]').length;
                    let normalColCount = $(colGroup[1]).children('td[role="gridcell"]').length;
                    let dataItem = grid.dataItem(colGroup[0]);

                    for (var i = 0; i < grid.columns.length; i++) {
                        var column = grid.columns[i];
                        var template = column.template;
                        var cell = null;

                        if (i >= frozenColCount) {
                            cell = $(colGroup[1]).children('td[role="gridcell"]').eq(i - frozenColCount);
                        }
                        else {
                            cell = $(colGroup[0]).children('td[role="gridcell"]').eq(i);
                        }

                        if (template !== undefined) {
                            var kendoTemplate = kendo.template(template);

                            if (template.contains("'checkbox'"))
                            {
                                //render the cell, special handle for angular event like ng-click
                                cell.html($compile(kendoTemplate(dataItem))(cell.scope().$parent));
                            }
                            else
                            {
                                //render the cell
                                cell.html(kendoTemplate(dataItem));
                            }
                        }
                        else {
                            var fieldValue = dataItem[column.field];

                            var format = column.format;
                            var values = column.values;

                            if (values !== undefined && values != null) {
                                // use the text value mappings (for enums)
                                for (var j = 0; j < values.length; j++) {
                                    var value = values[j];
                                    if (value.value == fieldValue) {
                                        cell.html(value.text);
                                        break;
                                    }
                                }
                            }
                            else if (format !== undefined) {
                                // use the format
                                cell.html(kendo.format(format, fieldValue));
                            }
                            else {
                                // Just dump the plain old value
                                cell.html(fieldValue);
                            }
                        }
                    }
                }
            }

            //this is to fix grid cannot drag and resize column after zoomed
            kendo.ui.Grid.prototype._positionColumnResizeHandle = function () {
                var that = this,
                    indicatorWidth = that.options.columnResizeHandleWidth,
                    lockedHead = that.lockedHeader ? that.lockedHeader.find("thead:first") : $();

                that.thead.add(lockedHead).on("mousemove" + ".kendoGrid", "th", function (e) {
                    var th = $(this);
                    if (th.hasClass("k-group-cell") || th.hasClass("k-hierarchy-cell")) {
                        return;
                    }
                    that._createResizeHandle(th.closest("div"), th);
                });
            };

            kendo.ui.Grid.prototype.getColumnIndexByName = function (columnName) {
                var index = -1;
                var grid = this;
                var columns = grid.options.columns;
                if (columns.length > 0) {
                    for (var i = 0; i < columns.length; i++) {
                        if (columns[i].field == columnName) {
                            // columns[i].title -- You can also use title property here but for this you have to assign title for all columns
                            index = i;
                        }

                        // check if the column is grouped
                        if (columns[i].columns !== undefined && columns[i].columns.length > 0) {
                            for (var j = 0; j < columns[i].columns.length; j++) {
                                if (columns[i].columns[i].field == columnName) {
                                    // columns[i].title -- You can also use title property here but for this you have to assign title for all columns
                                    index = i + j; // because the column index in a row is not grouped, the counting is continued inside the grouping
                                }
                            }
                        }
                    }
                }
                if (index !== -1) {
                    return index;
                }
                return index;
            }
        };

        if (typeof kendo !== "undefined") {
            if (kendo.data.DataSource.prototype.getItemsbyCompKey === undefined) {
                kendo.data.DataSource.prototype.getItemsbyCompKey = function (CompKey) {
                    return (this.data().reduce((prev, current) => {
                        if (current.CompKey == CompKey) {
                            prev.push(current.toJSON());
                        }

                        return prev;
                    }, []));
                };
            }

            if (kendo.data.DataSource.prototype.IsSubjectRequireApproval === undefined) {
                kendo.data.DataSource.prototype.IsSubjectRequireApproval = function (Subject, ClientKey, CompKey, OUKey, DeptKey, Amount) {
                    return (this.data().toJSON().findIndex(x =>
                        x.Subject == Subject
                        && (x.ClientKey == ClientKey || x.CompKey == CompKey || x.OUKey == OUKey || x.DeptKey == DeptKey)
                        && (x.ApprovalPolicy == "level" || x.ApprovalPolicy == "levelamount" || ((x.ApprovalPolicy == "amount" || x.ApprovalPolicy == "amountlevel") && x.LowerLimit < Amount && Amount <= x.UpperLimit))
                    )) >= 0;
                }
            }

            if (kendo.data.DataSource.prototype.getCompKeybyOUKey === undefined) {
                kendo.data.DataSource.prototype.getCompKeybyOUKey = function (OUKey) {
                    let item = this.data().find(x => x.OUKey == OUKey);

                    if (typeof item != "undefined") {
                        return item.CompKey;
                    }
                    else {
                        return -1;
                    }
                }
            }

            if (kendo.data.DataSource.prototype.getItemsbyOUKey === undefined) {
                kendo.data.DataSource.prototype.getItemsbyOUKey = function (OUKey) {
                    return (this.data().reduce((prev, current) => {
                        if (current.OUKey == OUKey) {
                            prev.push(current.toJSON());
                        }

                        return prev;
                    }, []));
                };
            }

            if (kendo.data.DataSource.prototype.getItemsbyFilter === undefined) {
                kendo.data.DataSource.prototype.getItemsbyFilter = function (filter) {
                    //clear filter to get full set of data
                    let tmpDataSource = this.customClone(false, false);

                    tmpDataSource.filter(filter);

                    return tmpDataSource._view;
                };
            }

            if (kendo.data.DataSource.prototype.getItemsbyKeyValuePair === undefined) {
                kendo.data.DataSource.prototype.getItemsbyKeyValuePair = function (Key, Value) {
                    return (this.data().reduce((prev, current) => {
                        if (current[Key] == Value) {
                            prev.push(current.toJSON());
                        }

                        return prev;
                    }, []));
                };
            }

            if (kendo.data.DataSource.prototype.readWithURL === undefined) {
                kendo.data.DataSource.prototype.readWithURL = function (url, model) {
                    let that = this;

                    return new Promise(function (resolve, reject) {
                        try {
                            if (model !== undefined) {
                                that.options.schema.model = model;
                            }

                            if (url !== undefined) {
                                if (url.indexOf("ExRateInfo") === -1) {
                                    that.transport.options.read.url = url;

                                    that.read().then(function () {
                                        resolve();
                                    }, function () {
                                            reject();
                                        });
                                }
                                else {
                                    _FillDataSource("ou").then(function (data) {
                                        let oukey = url.replace(/\s/g, "").substring(url.indexOf("OUKey=") + 6).replace(/\D/g, '');
                                        let compkey = "-1";

                                        let IsMultiCurrency = true;
                                        let LocalCurrKey = "-1"
                                        let LocalCurrCode = "";
                                        let LocalCurrDesc = "";
                                        let LocalCurrSymb = "";

                                        let cachedlen = data.length;
                                        for (var i = 0; i < cachedlen; i++) {
                                            if (data[i].OUKey.toString() === oukey) {
                                                compkey = data[i].CompKey.toString();
                                                break;
                                            }
                                        }

                                        if (compkey !== "-1") {
                                            _FillDataSource("currinfo").then(function (data1) {
                                                let cachedlen1 = data1.length;
                                                for (var j = 0; j < cachedlen1; j++) {
                                                    if (data1[j].CompKey.toString() === compkey) {
                                                        IsMultiCurrency = data1[j].IsMultiCurrency;
                                                        LocalCurrKey = data1[j].LocalCurrKey;
                                                        LocalCurrCode = data1[j].LocalCurrCode;
                                                        LocalCurrDesc = data1[j].LocalCurrDesc;
                                                        LocalCurrSymb = data1[j].LocalCurrSymb;
                                                        break;
                                                    }
                                                }

                                                if (IsMultiCurrency) {
                                                    that.transport.options.read.url = url;

                                                    that.read().then(function () {
                                                        resolve();
                                                    }, function () {
                                                            reject();
                                                        });
                                                }
                                                else {
                                                    //here hardcode data
                                                    that.data(JSON.parse('[{"ClientKey":0,"CurrKey":' + LocalCurrKey.toString() + ',"ExRate":"1","LocalExRate":"1","CurrCode":"' + LocalCurrCode.toString() + '","CurrSymb":"' + LocalCurrSymb.toString() + '","CurrDesc":"' + LocalCurrDesc.toString() + '","CurrCodeCurrDesc":"' + LocalCurrDesc.toString() + '"}]'));
                                                    resolve();
                                                }
                                            }, function () {
                                                    that.transport.options.read.url = url;

                                                    that.read().then(function () {
                                                        resolve();
                                                    }, function () {
                                                            reject();
                                                        });
                                                });
                                        }
                                        else {
                                            that.transport.options.read.url = url;

                                            that.read().then(function () {
                                                resolve();
                                            }, function () {
                                                    reject();
                                                });
                                        }
                                    }, function () {
                                            that.transport.options.read.url = url;

                                            that.read().then(function () {
                                                resolve();
                                            }, function () {
                                                    reject();
                                                });
                                        });
                                }
                            }
                            else {
                                that.transport.options.read.url = url;

                                that.read().then(function () {
                                    resolve();
                                }, function () {
                                        reject();
                                    });
                            }
                        }
                        catch (ex) {
                            that.transport.options.read.url = url;

                            that.read().then(function () {
                                resolve();
                            }, function () {
                                    reject();
                                });
                        }
                    });
                };
            }

            if (kendo.data.DataSource.prototype.customClone === undefined) {
                kendo.data.DataSource.prototype.customClone = function (enablePageSize, enableFilter) {
                    try {
                        let SourceData = this;

                        if (typeof SourceData !== "undefined") {
                            let DataSource = kendo.data.DataSource.create({
                                batch: SourceData.options.batch,
                                serverAggregates: SourceData.options.serverAggregates,
                                serverFiltering: SourceData.options.serverFiltering,
                                serverGrouping: SourceData.options.serverGrouping,
                                serverPaging: SourceData.options.serverPaging,
                                serverSorting: SourceData.options.serverSorting,
                                type: SourceData.options.type,
                                transport: SourceData.options.transport,
                                schema: SourceData.options.schema,
                                pageSize: (enablePageSize === true && typeof SourceData.pageSize() !== "undefined") ? SourceData.pageSize() : void 0,
                                filter: (enableFilter === true && typeof SourceData.filter() !== "undefined") ? _.cloneDeep(SourceData.filter()) : void 0
                            });

                            DataSource.data(SourceData.data().toJSON());

                            return DataSource
                        }
                        else {
                            return (new kendo.data.DataSource());
                        }
                    }
                    catch (err) {
                        console.log(err);
                        return (new kendo.data.DataSource());
                    }
                };
            }

            if (kendo.ui.ComboBox.prototype.kendoDefaultValueFunction === undefined) {
                kendo.ui.ComboBox.prototype.kendoDefaultValueFunction = kendo.ui.ComboBox.prototype.value;

                kendo.ui.ComboBox.prototype.value = function () {
                    // Call the original filter function.
                    var result = kendo.ui.ComboBox.prototype.kendoDefaultValueFunction.apply(this, arguments);

                    //assign value to combobox
                    if (arguments.length > 0) {
                        if (typeof arguments[0] !== "undefined") {
                            if (arguments[0] !== null) {
                                if (arguments[0] !== "") {
                                    this.trigger('open');
                                }
                            }
                        }
                    }

                    return result;
                }
            }

            if (kendo.ui.DropDownList.prototype.kendoDefaultValueFunction === undefined) {
                kendo.ui.DropDownList.prototype.kendoDefaultValueFunction = kendo.ui.DropDownList.prototype.value;

                kendo.ui.DropDownList.prototype.value = function () {
                    // Call the original filter function.
                    var result = kendo.ui.DropDownList.prototype.kendoDefaultValueFunction.apply(this, arguments);

                    //assign value to DropDownList
                    if (arguments.length > 0) {
                        if (typeof arguments[0] !== "undefined") {
                            if (arguments[0] !== null) {
                                if (arguments[0] !== "") {
                                    this.trigger('open');
                                }
                            }
                        }
                    }

                    return result;
                }
            }

            if (kendo.data.DataSource.prototype.filterCCID === undefined) {
                kendo.data.DataSource.prototype.filterCCID = function (OUKey, AccKey, showAllOU) {
                    if (OUKey === undefined) {
                        OUKey = -1;
                    }

                    if (AccKey === undefined) {
                        AccKey = -1;
                    }

                    if (showAllOU === undefined) {
                        showAllOU = false;
                    }

                    let ItemCostCenterType = "get lost, nothing here to see.";
                    let ItemCropKey = -999;

                    if (AccKey > -1) {
                        let AccInfo = (httpBase._decompress(_DataSourceCollection.SetFACOA.Data)).concat(httpBase._decompress(_DataSourceCollection.SetCOA.Data)).filter(function (element) {
                            return element.AccKey == AccKey;
                        });

                        if (AccInfo.length > 0) {
                            ItemCostCenterType = AccInfo[0].CostCenterType;

                            if (ItemCostCenterType === "Mature Block" || ItemCostCenterType === "New Planting Block" || ItemCostCenterType === "Replanting Block" || ItemCostCenterType === "Felling Block" || ItemCostCenterType === "Nursery Batch") {
                                ItemCropKey = AccInfo[0].CropKey;
                            }
                        }
                    }

                    let filterAnd =
                    {
                        logic: "and",
                        filters: []
                    };

                    if (showAllOU === true) {
                        //dont do anything
                        //go ask kee yong
                        //required in Account Ledger Detail listing
                        //when OUKey is -1. need show all acckey and its corresponding ccidkey
                    }
                    else {
                        filterAnd.filters.push({
                            field: "OUList",
                            operator: httpBase._OpContains,
                            value: OUKey
                        });
                    }

                    filterAnd.filters.push({
                        field: "CCTypeCodeList",
                        operator: httpBase._OpContains,
                        value: ItemCostCenterType
                    });

                    //these by category
                    if (!(ItemCostCenterType === "Fixed Asset" || ItemCostCenterType === "Store" || ItemCostCenterType === "Mature Block" || ItemCostCenterType === "New Planting Block" || ItemCostCenterType === "Replanting Block" || ItemCostCenterType === "Felling Block" || ItemCostCenterType === "Nursery Batch")) {
                        filterAnd.filters.push({
                            field: "AccList",
                            operator: httpBase._OpContains,
                            value: AccKey
                        });
                    }

                    //if block setup related, filter by cropkey
                    if (ItemCostCenterType === "Mature Block" || ItemCostCenterType === "New Planting Block" || ItemCostCenterType === "Replanting Block" || ItemCostCenterType === "Felling Block" || ItemCostCenterType === "Nursery Batch") {
                        filterAnd.filters.push({
                            field: "CropKey",
                            operator: "eq",
                            value: ItemCropKey
                        });
                    }

                    if (JSON.stringify(filterAnd) !== JSON.stringify(this.filter())) {
                        this.filter(filterAnd);
                    }

                    //just in case no data, at least second time trigger will get popolated successfully
                    if (_DataSourceCollection !== undefined) {
                        if (_DataSourceCollection.SetCOA !== undefined && _DataSourceCollection.SetFACOA !== undefined) {
                            if (_DataSourceCollection.SetCOA.IsAvaDataCOA === false) {
                                _FillDataSource("coa");
                            }

                            if (_DataSourceCollection.SetFACOA.IsAvaDataFACOA === false) {
                                _FillDataSource("onlyfacoa");
                            }
                        }
                    }
                };
            }
        }

        return _extendKendoDatasource;
    }());

    //////////////////////////////////////////////////////////////HTTP REQUESTS///////////////////////////////////////////////////////////////////////////////

    let _getReqExRate = function (strQuery) {
        if (strQuery === undefined) {
            strQuery = "";
        }
        let Req =
        {
            url: $rootScope.financeWebApiUrl + "FIN/GetExRate?" + strQuery,
            method: "GET"
        }

        return $http(Req);
    };

    let _getReqDocStateChanged = function (strQuery) {
        if (strQuery === undefined) {
            strQuery = "";
        }
        let Req =
        {
            url: $rootScope.financeWebApiUrl + "FIN/GetIsDocStateChanged?" + strQuery,
            method: "GET"
        }

        return $http(Req);
    };

    let _getReqYearEndMthStatus = function (strQuery) {
        if (strQuery === undefined) {
            strQuery = "";
        }
        let Req =
        {
            url: $rootScope.financeWebApiUrl + "FIN/GetFYandMthEndStatus?" + strQuery,
            method: "GET"
        }

        return $http(Req);
    };

    let _getReqGLHeader = function (strQuery) {
        if (strQuery === undefined) {
            strQuery = "";
        }
        let Req =
        {
            url: $rootScope.financeOdataUrl + "GLHeader?TransHdrKey=" + strQuery + "&$format=json",
            method: 'GET',
            jsonp: false,
            dataType: "json"
        }

        return $http(Req);
    };

    //////////////////////////////////////////////////////////////EXPOSED DATASOURCE///////////////////////////////////////////////////////////////////////////

    let _getDataExRate = function (strQuery) {
        if (strQuery === undefined) {
            strQuery = "";
            //strQuery = "Date=" + kendo.toString($scope.headerData.GLDate, 'd') + "&OUKey=" + $scope.headerData.OUKey;
        }
        let DataSource = new kendo.data.DataSource(
            {
                type: "odata",
                transport:
                {
                    read:
                    {
                        url: strQuery,
                        //url: $rootScope.masterOdataUrl + "ExRateInfo?" + strQuery + "&$format=json",
                        jsonp: false,
                        dataType: "json"
                    }
                },
                schema:
                {
                    data: function (data) {
                        return httpBase._parseStringToDecimal(data["value"]);
                    },
                    total: function (data) {
                        return data["odata.count"];
                    },
                    model:
                    {
                        id: "CurrKey",
                        fields:
                        {
                            CurrKey: { type: "number", editable: false, nullable: false },
                            CurrCode: { type: "string", editable: false, nullable: false },
                            ExRate: { type: "number", editable: false, nullable: false, defaultValue: 0 },
                            CurrDesc: { type: "string", editable: false, nullable: false },
                            CurrSymb: { type: "string", editable: false, nullable: false },
                        }
                    }
                },
                pageSize: $rootScope.comboBoxPageSize
            });

        return DataSource;
    };

    let _getPayeePayerAutoFillDs = function () {
        let DataSource = new kendo.data.DataSource({
            type: "odata",
            transport:
            {
                read:
                {
                    url: function () {
                        return $rootScope.financeWebApiUrl + "FIN/GetPayeePayer?$format=json&$select=PayTo";
                    },
                    method: 'GET',
                    jsonp: false,
                    dataType: "json",
                }
            },
            schema:
            {
                data: function (data) {
                    return httpBase._parseStringToDecimal(data);
                },
                total: function (data) {
                    return data.length;
                },
                model:
                {
                    id: "PayTo",
                    fields: { PayTo: { type: "string", editable: false, nullable: false } }
                }
            },
            pageSize: $rootScope.comboBoxPageSize
        });

        return DataSource;
    };

    let _getOTVendNameAutoFillDs = function () {
        let DataSource = new kendo.data.DataSource({
            type: "odata",
            transport:
            {
                read:
                {
                    url: function () {
                        return $rootScope.financeWebApiUrl + "FIN/GetOTVendName?$format=json&$select=VendorName,EmpyKey,Street,PostCode,City,State,CtryKey,Phone,Fax";
                    },
                    method: 'GET',
                    jsonp: false,
                    dataType: "json",
                }
            },
            schema:
            {
                data: function (data) {
                    return httpBase._parseStringToDecimal(data);
                },
                total: function (data) {
                    return data.length;
                },
                model:
                {
                    id: "VendorName",
                    fields: {
                        VendorName: { type: "string", editable: false, nullable: false },
                        EmpyKey: { type: "number", editable: false, nullable: false },
                        Street: { type: "string", editable: false, nullable: false },
                        PostCode: { type: "string", editable: false, nullable: false },
                        City: { type: "string", editable: false, nullable: false },
                        State: { type: "string", editable: false, nullable: false },
                        CtryKey: { type: "number", editable: false, nullable: false },
                        Phone: { type: "string", editable: false, nullable: false },
                        Fax: { type: "string", editable: false, nullable: false }
                    }
                }
            },
            pageSize: $rootScope.comboBoxPageSize
        });

        return DataSource;
    };

    let _getBeneficiaryDs = function () {
        let DataSource = new kendo.data.DataSource({
            type: "odata",
            transport:
            {
                read:
                {
                    url: function () {
                        return $rootScope.masterOdataUrl + "Beneficiary?$format=json&$select=BenKey,BenDesc,Active,BeneficiaryOU";
                    },
                    method: 'GET',
                    jsonp: false,
                    dataType: "json",
                }
            },
            schema:
            {
                data: function (data) {
                    return httpBase._parseStringToDecimal(data["value"]);
                },
                total: function (data) {
                    return data["odata.count"];
                },
                model:
                {
                    id: "BenKey",
                    fields: {
                        BenKey: { type: "number", editable: false, nullable: false },
                        BenDesc: { type: "string", editable: false, nullable: false },
                        Active: { type: "boolean", editable: false, nullable: false },
                        BeneficiaryOU: { type: "object", editable: false, nullable: false },
                    }
                }
            },
            pageSize: $rootScope.comboBoxPageSize
        });

        return DataSource;
    };

    ///////////////////////////////////////////////////////////////COLLECTION FOR ROOT SCOPE////////////////////////////////////////////////////////////////////

    let _Accessor = function (input) {
        switch (input) {
            case "uom":
                return "UOM";
                break;
            case "payterm":
                return "Payterm";
                break;
            case "currinfo":
                return "CurrInfo";
                break;
            case "grniacc":
                return "GRNIAcc";
            case "crniacc":
                return "CRNIAcc";
                break;
            case "bank":
                return "Bank";
                break;
            case "cash":
                return "Cash";
                break;
            case "gang":
                return "Gang";
                break;
            case "empy":
                return "Empy";
                break;
            case "paycode":
                return "Paycode";
                break;
            case "mill":
                return "Mill";
                break;
            case "veh":
                return "Veh";
                break;
            case "attdcode":
                return "Attdcode";
                break;
            case "post":
                return "Post";
                break;
            case "div":
                return "Div";
                break;
            case "store":
                return "Store";
                break;
            case "itemcat":
                return "Itemcat";
                break;
            case "itemmas":
                return "Itemmas";
                break;
            case "plantsource":
                return "Plantsource";
                break;
            case "nurbatch":
                return "Nurbatch";
                break;
            case "plantmate":
                return "Plantmate";
                break;
            case "dept":
                return "Dept";
                break;
            case "addrem":
                return "Addrem";
                break;
            case "taxded":
                return "TaxDed";
                break;
            case "ou":
                return "OU";
                break;
            case "coa":
                return "COA";
                break;
            case "contact":
                return "Contact";
                break;
            case "block":
                return "Block";
                break;
            case "activity":
                return "Activity";
                break;
            case "company":
                return "Company";
                break;
            case "aprvlvl":
                return "AprvLvl";
                break;
            case "ccid":
                return "CCID";
                break;
            case "acccat":
                return "AccCat";
                break;
            case "fin":
                return "Fin";
                break;
            case "tax":
                return "Tax";
                break;
            case "terrain":
                return "Terrain";
                break;
            case "soiltype":
                return "SoilType";
                break;
            case "uncularea":
                return "UnculArea";
                break;
            case "bgtprep":
                return "BgtPrep";
                break;
            case "facoa":
                return "FACOA";
                break;
            case "faccid":
                return "FACCID";
                break;
            case "onlyfacoa":
                return "FACOA";
                break;
            case "onlyfaccid":
                return "FACCID";
                break;
            case "field":
                return "Field";
                break;
            case "stoconfg":
                return "STOConfg";
                break;
            case "vegetype":
                return "VegeType";
                break;
            case "vege":
                return "Vege";
                break;
            case "salesitem":
                return "SalesItem";
                break;
            case "clientnotification":
                return "ClientNotification";
                break;
            case "wgitem":
                return "WgItem";
                break;
            case "type":
                return "Type";
                break;
            case "typeID":
                return "TypeID";
                break;
            default:
                return [
                    "UOM",
                    "Payterm",
                    "CurrInfo",
                    "GRNIAcc",
                    "CRNIAcc",
                    "Bank",
                    "Cash",
                    "Gang",
                    "Empy",
                    "Paycode",
                    "Mill",
                    "Veh",
                    "Attdcode",
                    "Post",
                    "Div",
                    "Store",
                    "Itemcat",
                    "Itemmas",
                    "Plantsource",
                    "Nurbatch",
                    "Plantmate",
                    "Dept",
                    "Addrem",
                    "TaxDed",
                    "OU",
                    "COA",
                    "Contact",
                    "Block",
                    "Activity",
                    "Company",
                    "AprvLvl",
                    "CCID",
                    "AccCat",
                    "Fin",
                    "Tax",
                    "Terrain",
                    "SoilType",
                    "UnculArea",
                    "BgtPrep",
                    "FACOA",
                    "FACCID",
                    "Field",
                    "STOConfg",
                    "VegeType",
                    "Vege",
                    "SalesItem",
                    "ClientNotification",
                    "WgItem",
                    "Type",
                    "TypeID"
                ];
                break;
        }
    };

    let _DataSourceCollection =
    {
        //flag to set if datasource is always reloaded
        //IsAlwaysReloadxxxxxx
        //flag to check if datasource is available
        //IsAvaDataxxxxxxxxxxx
        CurrentPCUniqueID: httpBase._guid(),
        SetUOM: {
            IsAlwaysReloadUOM: false,
            IsAvaDataUOM: false,
            Sort: { field: "UOMCode", dir: "asc" },
            Model: {
                id: "UOMKey",
                fields: {
                    UOMKey: { type: "number", editable: false, nullable: false },
                    UOMCode: { type: "string", editable: false, nullable: false },
                    UOMDesc: { type: "string", editable: false, nullable: false },
                    Active: { type: "boolean", editable: false, nullable: false }
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.masterOdataUrl + "UOM?$format=json&$select=UOMKey,OUKey,UOMCode,UOMDesc,Active,ClientKey,UOMCodeUOMDesc,OUCode,OUCodeOUDesc,Symbol",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetPayterm: {
            IsAlwaysReloadPayterm: false,
            IsAvaDataPayterm: false,
            Sort: { field: "PayTermCode", dir: "asc" },
            Model: {
                id: "PayTermKey",
                fields: {
                    PayTermKey: { type: "number", editable: false, nullable: false, defaultValue: 0 },
                    PayTermCode: { type: "string", editable: false, nullable: false },
                    PayTermDesc: { type: "string", editable: false, nullable: false },
                    PayTermType: { type: "string", editable: false, nullable: false },
                    PayTermCodePayTermDesc: { type: "string", editable: false, nullable: false },
                    DueDay: { type: "number", editable: false, nullable: false },
                    DueMth: { type: "number", editable: false, nullable: false },
                    Active: { type: "boolean", editable: false, nullable: false },
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.masterOdataUrl + "PayTerm?$format=json&$select=PayTermKey,ClientKey,OUKey,PayTermCode,PayTermDesc,PayTermType,PayTermTypeDesc,DueDay,DueMth,Active,PayTermCodePayTermDesc,OUDesc",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetCurrInfo: {
            IsAlwaysReloadCurrInfo: false,
            IsAvaDataCurrInfo: false,
            Sort: { field: "LocalCurrCode", dir: "asc" },
            Model: {
                id: "LocalCurrKey",
                fields: {
                    CompKey: { type: "string", editable: false, nullable: false },
                    IsMultiCurrency: { type: "boolean", editable: false, nullable: false },
                    LocalCurrKey: { type: "number", editable: false, nullable: false },
                    LocalCurrCode: { type: "string", editable: false, nullable: false },
                    LocalCurrDesc: { type: "string", editable: false, nullable: false },
                    TaxCurrKey: { type: "number", editable: false, nullable: false },
                    TaxCurrCode: { type: "string", editable: false, nullable: false },
                    TaxCurrDesc: { type: "string", editable: false, nullable: false },
                    TaxCurrSymb: { type: "string", editable: false, nullable: false },
                    GSTClaimAccKey: { type: "number", editable: false, nullable: false },
                    GSTClaimAccNum: { type: "string", editable: false, nullable: false },
                    GSTLiabilityAccKey: { type: "number", editable: false, nullable: false },
                    GSTLiabilityAccNum: { type: "string", editable: false, nullable: false },
                    LocalCurrSymb: { type: "string", editable: false, nullable: false },
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.financeWebApiUrl + "FIN/GetSingleCurrInfo?$format=json&$select=IsMultiCurrency,LocalCurrKey,LocalCurrCode,LocalCurrDesc,LocalCurrSymb,TaxCurrKey,TaxCurrCode,TaxCurrDesc,TaxCurrSymb,GSTClaimAccKey,GSTClaimAccNum,GSTLiabilityAccKey,GSTLiabilityAccNum,CompKey",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetGRNIAcc: {
            IsAlwaysReloadGRNIAcc: false,
            IsAvaDataGRNIAcc: false,
            Sort: { field: "GRNIAccKey", dir: "asc" },
            Model: {
                id: "GRNIAccKey",
                fields: {
                    CompKey: { type: "number", editable: false, nullable: false },
                    GRNIAccKey: { type: "number", editable: false, nullable: false },
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.financeWebApiUrl + "FIN/GetGRNIAccKey?$format=json&$select=GRNIAccKey,CompKey",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetCRNIAcc: {
            IsAlwaysReloadCRNIAcc: false,
            IsAvaDataCRNIAcc: false,
            Sort: { field: "CRNIAccKey", dir: "asc" },
            Model: {
                id: "CRNIAccKey",
                fields: {
                    CompKey: { type: "number", editable: false, nullable: false },
                    CRNIAccKey: { type: "number", editable: false, nullable: false },
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.financeWebApiUrl + "FIN/GetCRNIAccKey?$format=json&$select=CRNIAccKey,CompKey",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetBank: {
            IsAlwaysReloadBank: false,
            IsAvaDataBank: false,
            Sort: { field: "BankCode", dir: "asc" },
            Model: {
                id: "BankKey",
                fields: {
                    BankKey: { type: "number", editable: false, nullable: false, defaultValue: 0 },
                    BankCode: { type: "string", editable: false, nullable: false },
                    BankCodeBankDesc: { type: "string", editable: false, nullable: false },
                    BankAcc: { type: "string", editable: false, nullable: false },
                    BankDesc: { type: "string", editable: false, nullable: false },
                    CurrCode: { type: "string", editable: false, nullable: false },
                    PhyBankAcc: { type: "string", editable: false, nullable: false },
                    Active: { type: "boolean", editable: false, nullable: false },
                    EnableBulkPay: { type: "boolean", editable: false, nullable: false },
                    EnableOnlinePaySta: { type: "boolean", editable: false, nullable: false },
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.masterOdataUrl + "Bank?$format=json&$select=BankKey,ClientKey,BankCode,BankName,BankDesc,Active,CtryKey,BankAccKey,CurrKey,CurrCode,CurrDesc,PhyBankAcc,BankAcc,CCIDTypeKey,CCIDKey,BNMCode,BNM,AccDesc,AccNumAccDesc,BankCodeBankDesc,BankOUKey,BICKey,StateKey,APOrgCode,PayStaSubmit,FormatCode,BankCompKey,EnableBulkPay,SwiftCode,EnableOnlinePaySta",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetCash: {
            IsAlwaysReloadCash: false,
            IsAvaDataCash: false,
            Sort: { field: "CashCode", dir: "asc" },
            Model: {
                id: "CashKey",
                fields: {
                    CashKey: { type: "number", editable: false, nullable: false, defaultValue: 0 },
                    CashAccKey: { type: "number", editable: false, nullable: false, defaultValue: 0 },
                    CashCode: { type: "string", editable: false, nullable: false },
                    CashAcc: { type: "string", editable: false, nullable: false },
                    CashDesc: { type: "string", editable: false, nullable: false },
                    Active: { type: "boolean", editable: false, nullable: false },
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.masterOdataUrl + "Cash?$format=json&$select=CashKey,ClientKey,CashCode,CashName,CashDesc,Active,CashAccKey,CurrKey,CurrCode,CurrDesc,CashAcc,AccDesc,AccNumAccDesc,CashCodeCashDesc,CCIDTypeKey,CCIDKey,CashOUKey,CashCompKey",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetGang: {
            IsAlwaysReloadGang: false,
            IsAvaDataGang: false,
            Sort: { field: "GangCode", dir: "asc" },
            Model: {
                id: "GangKey",
                fields: {
                    GangKey: { type: "number", editable: false, nullable: false },
                    OUKey: { type: "number", editable: false, nullable: false },
                    GangCode: { type: "string", editable: false, nullable: false },
                    GangDesc: { type: "string", editable: false, nullable: false },
                    GangCodeGangDesc: { type: "string", editable: false, nullable: false },
                    Active: { type: "boolean", editable: false, nullable: false },
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.masterOdataUrl + "Gang?$format=json&$select=GangKey,ClientKey,OUKey,CompKey,GangCode,GangDesc,Active,EmpyKey,CheckerKey,Checker,DefHarMdr,DefHarMdr2,DefHarMdrOUKey,DftHarPymtMtd,GangCodeGangDesc,EmpyIDEmpyName,EmpyIDEmpyName1,DefHarMdr2IDName,OUCodeOUDesc,OUCode",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetEmpy: {
            IsAlwaysReloadEmpy: false,
            IsAvaDataEmpy: false,
            Sort: { field: "EmpyID", dir: "asc" },
            Model: {
                id: "EmpyKey",
                fields: {
                    EmpyKey: { type: "number", editable: false, nullable: false },
                    EmpyIDEmpyName: { type: "string", editable: false, nullable: false },
                    GangKey: { type: "number", editable: false, nullable: false },
                    GangCode: { type: "string", editable: false, nullable: false },
                    DRate: { type: "number", editable: false, nullable: false, defaultValue: 0 },
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.masterOdataUrl + "EmpyPerMas?$format=json&$select=EmpyKey,EmpyID,EmpyName,EmpyIDEmpyName,GangKey,GangCode,GangCodeGangDesc,OUCodeOUDesc,OUCode,DRate,MRate,EnablePB,LProfileKey,OTProKey,FWBatchCode,EmplCat,FirstDayWork,LastDayWork,PRType,PostKey,PRTypeDesc,PostDesc,DeptKey,DeptCode,Status,EnableDP,EnableMP,EnableTax,OUKey,EnablePayPro,DeptCodeDeptDesc,EnableEmailPayslip,DivKey",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetPaycode: {
            IsAlwaysReloadPaycode: false,
            IsAvaDataPaycode: false,
            Sort: { field: "PayCode", dir: "asc" },
            Model: {
                id: "PayKey",
                fields: {
                    PayKey: { type: "number", editable: false, nullable: false, defaultValue: -1 },
                    PayCode: { type: "string", editable: false, nullable: false },
                    PayCodePayDesc: { type: "string", editable: false, nullable: false },
                    AccKey: { type: "number", editable: false, nullable: false, defaultValue: -1 },
                    AccNumAccDesc: { type: "string", editable: false, nullable: false },
                    CCIDKey: { type: "number", editable: false, nullable: false, defaultValue: -1 },
                    CCIDCodeCCIDDesc: { type: "string", editable: false, nullable: false },
                    IsRequiredCCID: { type: "boolean", editable: false, nullable: false },
                    IsAlwChangeRate: { type: "boolean", editable: false, nullable: false },
                    EnableProRate: { type: "boolean", editable: false, nullable: false },
                    DftAmt: { type: "number", editable: false, nullable: false, defaultValue: 0 },
                    Module: { type: "string", editable: false, nullable: false },
                    PayCodeType: { type: "string", editable: false, nullable: false },
                    Active: { type: "boolean", editable: false, nullable: false },
                    RecTypeKey: { type: "number", editable: false, nullable: false, defaultValue: -1 },
                    RecTypeCodeRecTypeDesc: { type: "string", editable: false, nullable: false },
                    GrpType: { type: "string", editable: false, nullable: false },
                    GrpCode: { type: "string", editable: false, nullable: false },
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.masterOdataUrl + "PayCode?$format=json&$select=PayKey,OUKey,OUCode,PayCode,PayCodeType,PayDesc,Active,PayGrpKey,PayGrpCodeGrpDesc,GrpCode,EPFCont,TaxCont,SOCSOCont,DftAmt,AccKey,AccNum,AccNumAccDesc,CCIDKey,IsRequiredCCID,CCIDCode,CCIDCodeCCIDDesc,PayCodePayDesc,RecTypeKey,RecTypeCodeRecTypeDesc,IsTaxPPh,IsTaxAllow,TaxExpType,Module,GrpType,OTRate,IsAlwChangeRate,EnableProRate",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetMill: {
            IsAlwaysReloadMill: false,
            IsAvaDataMill: false,
            Sort: { field: "MillCode", dir: "asc" },
            Model: {
                id: "MillKey",
                fields: {
                    MillKey: { type: "number", editable: false, nullable: false, defaultValue: 0 },
                    MillCode: { type: "string", editable: false, nullable: false },
                    MillCodeDesc: { type: "string", editable: false, nullable: false },
                    Active: { type: "boolean", editable: false, nullable: false },
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.masterOdataUrl + "Mill?$format=json&$select=MillKey,MillCode,MillDesc,Active,MillType,ClientKey,OUKey,ContactKey,Remarks,MillCodeDesc,MillOUKey",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetVeh: {
            IsAlwaysReloadVeh: false,
            IsAvaDataVeh: false,
            Sort: { field: "VehID", dir: "asc" },
            Model: {
                id: "VehKey",
                fields: {
                    VehKey: { type: "number", editable: false, nullable: false, defaultValue: 0 },
                    ClientKey: { type: "number", editable: false, nullable: false, defaultValue: 0 },
                    RegNo: { type: "string", editable: false, nullable: false },
                    VehIDVehDesc: { type: "string", editable: false, nullable: false },
                    VehID: { type: "string", editable: false, nullable: false },
                    Active: { type: "boolean", editable: false, nullable: false },
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                //url: $rootScope.masterOdataUrl + "Veh?$format=json&$select=VehKey,VehTypeKey,VehID,VehDesc,Active,PriUOMKey,FuelUOMKey,SecUOMKey,LocKey,RegNo,MthPurchased,YrMade,Make,Model,EngineCC,SerialNo,EngineVIN,ChasisVIN,TyreFront,TyreRear,RdExpiredDate,HasCL,CLDate,HasJPJ,JPJDate,HasNS,NSDate,Remarks,TrnFrom,TrnTo,TrnDate,ServInterval,LastMeter,OUKey,ClientKey,CompKey,VehTypeCode,LocDesc,OUDesc,OUCode,PriDesc,VehIdVehDesc,FuelDesc,SecDesc,CCIDTypeKey,PriUnitRate",
                url: $rootScope.masterOdataUrl + "Veh?$format=json&$select=VehKey,VehTypeKey,VehID,VehDesc,Active,RegNo,OUKey,ClientKey,CompKey,VehTypeCode,OUDesc,OUCode,VehIdVehDesc,CCIDTypeKey,PriUOMKey,FuelUOMKey,SecUOMKey,PriDesc,FuelDesc,SecDesc,PriUnitRate",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetAttdcode: {
            IsAlwaysReloadAttdcode: false,
            IsAvaDataAttdcode: false,
            Sort: { field: "AttdCode", dir: "asc" },
            Model: {
                id: "AttdKey",
                fields: {
                    AttdKey: { type: "number", editable: false, nullable: false },
                    AttdCode: { type: "string", editable: false, nullable: false },
                    AttdCodeAttdDesc: { type: "string", editable: false, nullable: false },
                    ColorInd: { type: "string", editable: false, nullable: false },
                    PRType: { type: "string", editable: false, nullable: false },
                    Active: { type: "boolean", editable: false, nullable: false },
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.masterOdataUrl + "AttdCode?$format=json&$select=AttdKey,ClientKey,AttdCode,AttdDesc,AttdCodeAttdDesc,Active,OUKey,OUCode,ColorInd,SeqNo,AttdTypeKey,PRType,PRTypeDesc,AllowPay,PayMtd,PayMtdDesc,PayRateMtd,PayRateMtdDesc,EnablePB,PBType,PBTypeDesc,IsIncMthAvgIncome,DPF,DPFString,AttdTypeCode,AttdTypeDesc,AttdTypeAllowPay,IsWrkDay,OUCodeOUDesc,EnableOT,EnableAllocOT,AllowEmptyPR,AllowZeroMD,MDDefaultVal,IsDutyCompleted,OfferDay",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetPost: {
            IsAlwaysReloadPost: false,
            IsAvaDataPost: false,
            Sort: { field: "PostCode", dir: "asc" },
            Model: {
                id: "PostKey",
                fields: {
                    PostKey: { type: "number", editable: false, nullable: false },
                    DivKey: { type: "number", editable: false, nullable: false },
                    PostCode: { type: "string", editable: false, nullable: false },
                    PostDesc: { type: "string", editable: false, nullable: false },
                    PostCodePostDesc: { type: "string", editable: false, nullable: false },
                    Active: { type: "boolean", editable: false, nullable: false },
                    PostOUKey: { type: "object", editable: false, nullable: false },

                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.masterOdataUrl + "Post?$format=json&$select=PostKey,PostCode,PostDesc,PostCodePostDesc,PostOUKey,Active",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetDiv: {
            IsAlwaysReloadDiv: false,
            IsAvaDataDiv: false,
            Sort: { field: "DivCode", dir: "asc" },
            Model: {
                id: "DivKey",
                fields: {
                    DivKey: { type: "number", editable: false, nullable: false, defaultValue: 0 },
                    DivCode: { type: "string", editable: false, nullable: false, dir: "asc" },
                    DivDesc: { type: "string", editable: false, nullable: false },
                    DivCodeDivDesc: { type: "string", editable: false, nullable: false },
                    Active: { type: "boolean", editable: false, nullable: false },
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.masterOdataUrl + "Division?$format=json&$select=DivKey,ClientKey,OUKey,DivCode,DivDesc,Active,DivCodeDivDesc,OUCodeOUDesc,CompKey,CompCodeCompDesc,PlantedArea,GrossArea,OUCode",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetStore: {
            IsAlwaysReloadStore: false,
            IsAvaDataStore: false,
            Sort: { field: "StoreCode", dir: "asc" },
            Model: {
                id: "StoreKey",
                fields: {
                    StoreKey: { type: "number", editable: false, nullable: false, defaultValue: 0 },
                    StoreCode: { type: "string", editable: false, nullable: false },
                    StoreDesc: { type: "string", editable: false, nullable: false },
                    StoreCodeStoreDesc: { type: "string", editable: false, nullable: false },
                    Active: { type: "boolean", editable: false, nullable: false },
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.masterOdataUrl + "Store?$format=json&$select=StoreKey,StoreCode,StoreDesc,StoreCodeStoreDesc,ClientKey,Active,TransHierachy,IsMainStore,ClientCode,ClientName,OUKey,OUCode,OUDesc,CompKey,CompCode,CompDesc,CompCodeCompDesc,CCIDTypeKey",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetItemcat: {
            IsAlwaysReloadItemcat: false,
            IsAvaDataItemcat: false,
            Sort: { field: "ItemCatCode", dir: "asc" },
            Model: {
                id: "ItemCatKey",
                fields: {
                    ItemCatKey: { type: "number", nullable: false },
                    ItemCatCode: { type: "string", nullable: false },
                    ItemCatDesc: { type: "string", nullable: false },
                    ItemCatCodeCatDesc: { type: "string", nullable: false },
                    Active: { type: "boolean", editable: false, nullable: false },
                    EnableAutoNo: { type: "boolean", editable: false, nullable: false },
                    NumLength: { type: "number", editable: false, nullable: false },
                    NextNum: { type: "number", editable: false, nullable: false },
                    Prefix: { type: "string", editable: false, nullable: false },
                    NextItemID: { type: "string", editable: false, nullable: false },
                    Type: { type: "string", editable: false, nullable: false }
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.masterOdataUrl + "ItemCat?$format=json&$select=ItemCatKey,ClientKey,ItemCatCode,ItemCatDesc,Active,ItemTypeKey,EnableAutoNo,NumLength,NextNum,NextItemID,ItemTypeDesc,ItemCatCodeCatDesc,Prefix,Type",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetItemmas: {
            IsAlwaysReloadItemmas: false,
            IsAvaDataItemmas: false,
            Sort: { field: "ItemID", dir: "asc" },
            Model: {
                id: "StoItemKey",
                fields: {
                    StoItemKey: { type: "number", editable: false, nullable: false, defaultValue: 0 },
                    ItemID: { type: "string", editable: false, nullable: false },
                    ItemDesc: { type: "string", editable: false, nullable: false },
                    ItemCatKey: { type: "number", editable: false, nullable: false },
                    ItemCatCode: { type: "string", editable: false, nullable: false },
                    ItemCatDesc: { type: "string", editable: false, nullable: false },
                    StoItemPhotoKey: { type: "number", editable: false, nullable: false },
                    ItemPhoto: { type: "byte[]", editable: false, nullable: false },
                    ItemIDItemDesc: { type: "string", editable: false, nullable: false },
                    ItemCatCodeCatDesc: { type: "string", editable: false, nullable: false },
                    Active: { type: "boolean", editable: false, nullable: false },
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.masterOdataUrl + "ItemMas?dummy=-1&dummy1=-1&StoItemKey=-1&$format=json",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetPlantsource: {
            IsAlwaysReloadPlantsource: false,
            IsAvaDataPlantsource: false,
            Sort: { field: "PlantSourceCode", dir: "asc" },
            Model: {
                id: "PlantSourceKey",
                fields: {
                    PlantSourceKey: { type: "number", editable: false, nullable: false },
                    PlantSourceCode: { type: "string", editable: false, nullable: false },
                    PSCodePSDesc: { type: "string", editable: false, nullable: false },
                    Active: { type: "boolean", editable: false, nullable: false },
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.masterOdataUrl + "PlantSource?$format=json&$select=PlantSourceKey,ClientKey,OUKey,OUCode,OUDesc,PlantSourceCode,PlantSourceDesc,Active,PSCodePSDesc",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetNurbatch: {
            IsAlwaysReloadNurbatch: false,
            IsAvaDataNurbatch: false,
            Sort: { field: "NurBatchCode", dir: "asc" },
            Model: {
                id: "NurBatchKey",
                fields: {
                    NurBatchKey: { type: "number", editable: false, nullable: false },
                    NurBatchCode: { type: "string", editable: false, nullable: false },
                    NurBatchCodeDesc: { type: "string", editable: false, nullable: false },
                    Active: { type: "boolean", editable: false, nullable: false },
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.masterOdataUrl + "NurseryBatch?$format=json&$select=NurBatchKey,ClientKey,OUKey,NurBatchCode,NurBatchDesc,NurBatchCodeDesc,PlantMateCodeDesc,OUCodeOUDesc,OUCode,OUDesc,Active,PlantedYr,PlantedMth,PlantedYear,PlantedMonth,PlantMateKey,IsProgramComplete,IsAutoNurPreSold,IsAutoNurMainSold,IsAutoNurTransPlant,CompKey,CCIDTypeKey,PreNursaryAccKey,MainNursaryAccKey,ImmatureAccKey,MatureAccKey,TransferToFieldAccKey,CropKey",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetPlantmate: {
            IsAlwaysReloadPlantmate: false,
            IsAvaDataPlantmate: false,
            Sort: { field: "PlantMateCode", dir: "asc" },
            Model: {
                id: "PlantMateKey",
                fields: {
                    PlantMateKey: { type: "number", editable: false, nullable: false },
                    PlantMateCode: { type: "string", editable: false, nullable: false },
                    PMCodePMDesc: { type: "string", editable: false, nullable: false },
                    Active: { type: "boolean", editable: false, nullable: false },
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.masterOdataUrl + "PlantMate?$format=json&$select=PlantMateKey,ClientKey,OUKey,OUCode,OUDesc,PlantMateCode,PlantMateDesc,Active,PMCodePMDesc",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetDept: {
            IsAlwaysReloadDept: false,
            IsAvaDataDept: false,
            Sort: { field: "DeptCode", dir: "asc" },
            Model: {
                id: "DeptKey",
                fields: {
                    DeptKey: { type: "number", editable: false, nullable: false },
                    ClientKey: { type: "number", editable: false, nullable: false },
                    DeptCode: { type: "string", editable: false, nullable: false },
                    DeptDesc: { type: "string", editable: false, nullable: false },
                    DeptCodeDeptDesc: { type: "string", editable: false, nullable: false },
                    Active: { type: "boolean", editable: false, nullable: false },
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.masterOdataUrl + "Dept?$format=json&$select=DeptKey,ClientKey,DeptCode,DeptDesc,DeptCodeDeptDesc,Active, DeptOUKey",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetAddrem: {
            IsAlwaysReloadAddrem: false,
            IsAvaDataAddrem: false,
            Sort: { field: "AddRemCode", dir: "asc" },
            Model: {
                id: "AddRemKey",
                fields: {
                    AddRemKey: { type: "number", editable: false, nullable: false },
                    AddRemCode: { type: "string", editable: false, nullable: false },
                    AddRemDesc: { type: "string", editable: false, nullable: false },
                    AddRemCodeAddRemDesc: { type: "string", editable: false, nullable: false },
                    TypeDesc: { type: "string", editable: false, nullable: false },
                    Active: { type: "boolean", editable: false, nullable: false },
                    IsRequiredCCID: { type: "boolean", editable: false, nullable: false },
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.masterOdataUrl + "AddRem?$format=json&$select=OUKey,OUCode,AddRemKey,AddRemCodeAddRemDesc,Active",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetTaxDed: {
            IsAlwaysReloadTaxDed: false,
            IsAvaDataTaxDed: false,
            Sort: { field: "TaxDedCode", dir: "asc" },
            Model: {
                id: "AddRemKey",
                fields: {
                    TaxDedKey: { type: "number", editable: false, nullable: false },
                    TaxDedCode: { type: "string", editable: false, nullable: false },
                    TaxDedDesc: { type: "string", editable: false, nullable: false },
                    TaxDedCodeTaxDedDesc: { type: "string", editable: false, nullable: false },
                    DedAmt: { type: "number", editable: false, nullable: false, defaultValue: 0, validation: { min: 0 } },
                    Yr: { type: "number", editable: false, nullable: false },
                    Type: { type: "string", editable: false, nullable: false },
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.systemOdataUrl + "TaxDed?$format=json&$select=TaxDedKey,TaxDedCode,TaxDedDesc,Type,Amount,NoOfYears,OUKey,CompKey,ClientKey,Yr,TaxDedCodeTaxDedDesc",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetOU: {
            IsAlwaysReloadOU: false,
            IsAvaDataOU: false,
            Sort: { field: "OUCode", dir: "asc" },
            Model: {
                id: "OUKey",
                fields: {
                    CompKey: { type: "number", editable: false, nullable: false },
                    OUKey: { type: "number", editable: false, nullable: false },
                    OUCode: { type: "string", editable: false, nullable: false },
                    OUDesc: { type: "string", editable: false, nullable: false },
                    OUCodeOUDesc: { type: "string", editable: false, nullable: false },
                    EnableNoTaxChecking: { type: "boolean", editable: false, nullable: false },
                    GSTRegNo: { type: "string", editable: false, nullable: false },
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.masterOdataUrl + "UserInOU?$format=json&",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetCOA: {
            IsAlwaysReloadCOA: false,
            IsAvaDataCOA: false,
            Sort: { field: "AccNum", dir: "asc" },
            Model: {
                id: "AccKey",
                fields: {
                    AccKey: { type: "number", editable: false, nullable: false },
                    AccNum: { type: "string", editable: false, nullable: false },
                    AccDesc: { type: "string", editable: false, nullable: false },
                    AccNumAccDesc: { type: "string", editable: false, nullable: false },
                    AccCatKey: { type: "number", editable: false, nullable: false },
                    AccType: { type: "string", editable: false, nullable: false },
                    AnlysType: { type: "string", editable: false, nullable: false },
                    OperationTypeDesc: { type: "number", editable: false, nullable: true },
                    IsVFuelAcc: { type: "boolean", editable: false, nullable: false },
                    IsVLubAcc: { type: "boolean", editable: false, nullable: false },
                    Active: { type: "boolean", editable: false, nullable: false },
                    AccMasOUKey: { type: "object", editable: false, nullable: false },
                    AccSubCatKey: { type: "number", editable: false, nullable: false },
                    CropKey: { type: "number", editable: false, nullable: false },
                    AccSubType: { type: "number", editable: false, nullable: false },
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.masterOdataUrl + "COA?isShowEverything=false&isShowFAonly=false&$format=json&$select=AccKey,AccNum,AccDesc,AccCatKey,AccCatSegKey,AccCatCode,AccCatDesc,AccType,AnlysType,AllowRevalue,OperationTypeKey,OperationTypeCode,OperationTypeDesc,IsVFuelAcc,IsVLubAcc,ClientKey,Active,CFTypeKey,CFTypeDesc,AccNumAccDesc,IsRequiredCCID,CostCenterType,UOMKey,UOMCode,UOMCodeUOMDesc,IsControlAccount,AccMasOUKey,CropKey,AccSubCatKey,AccSubCatDetKey,AccSubType",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetContact: {
            IsAlwaysReloadContact: false,
            IsAvaDataContact: false,
            Sort: { field: "ContactCode", dir: "asc" },
            Model: {
                id: "ContactKey",
                fields: {
                    ContactKey: { type: "number", editable: false, nullable: false, defaultValue: 0 },
                    ClientKey: { type: "number", editable: false, nullable: false, defaultValue: 0 },
                    ContactCode: { type: "string", editable: false, nullable: false },
                    ContactDesc: { type: "string", editable: false, nullable: false },
                    ConCat: { type: "string", editable: false, nullable: false },
                    RcvAccNum: { type: "number", editable: false, nullable: false },
                    RcvAccDesc: { type: "string", editable: false, nullable: false },
                    PayAccNum: { type: "number", editable: false, nullable: false },
                    PaymentType: { type: "string", editable: false, nullable: false },
                    PayTermKey: { type: "number", editable: false, nullable: false },
                    PayTermCode: { type: "string", editable: false, nullable: false },
                    CurrKey: { type: "number", editable: false, nullable: false },
                    ContactOUKey: { type: "string", editable: false, nullable: false },
                    ContactCompKey: { type: "string", editable: false, nullable: false },
                    ContactCatDesc: { type: "string", editable: false, nullable: false },
                    BenDetails: { type: "object", editable: false, nullable: false },
                    IsOneTime: { type: "boolean", editable: false, nullable: false },
                    OneTimeType: { type: "string", editable: false, nullable: false },
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.masterOdataUrl + "Contact?formName='All'&$select=BenDetails,ContactKey,ClientKey,ContactCode,ContactDesc,ConCat,ConCatDesc,RcvAccKey,RcvAccNum,RcvAccDesc,PayAccKey,PayAccNum,PayAccDesc,BusRegNo,GSTRegNo,PaymentType,PaymentTypeDesc,PayTermKey,PayTermCode,PayTermDesc,CurrKey,CurrCode,CurrDesc,Active,ContactCodeContactDesc,IsGSTCtrlAcc,ContactAccKey,ContactAccNum,ContactAccDesc,ContactOUKey,ContactCompKey,CCIDTypeKey,CCIDKey,ContactCategory,ContactCatCode,ContactCatDesc,ContactCatCodeContactCatDesc,IsTaxRegistered,InterOUCompKey,ExcludeAging,Tier,BillStreet,BillPostCode,BillCity,BillState,BillCtryDesc,ShipStreet,ShipPostCode,ShipCity,ShipState,ShipCtryDesc,ContactType,IsOneTime,OneTimeType",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetBlock: {
            IsAlwaysReloadBlock: false,
            IsAvaDataBlock: false,
            Sort: { field: "BlockCode", dir: "asc" },
            Model: {
                id: "BlockKey",
                fields: {
                    BlockKey: { type: "number", editable: false, nullable: false, defaultValue: 0 },
                    BlockCode: { type: "string", editable: false, nullable: false },
                    BlockDesc: { type: "string", editable: false, nullable: false },
                    BlockCodeBlockDesc: { type: "string", editable: false, nullable: false },
                    DivKey: { type: "number", editable: false, nullable: false },
                    DivCode: { type: "string", editable: false, nullable: false },
                    DivDesc: { type: "string", editable: false, nullable: false },
                    FieldKey: { type: "number", editable: false, nullable: false },
                    FieldCode: { type: "string", editable: false, nullable: false },
                    FieldDesc: { type: "string", editable: false, nullable: false },
                    FieldCodeFieldDesc: { type: "string", editable: false, nullable: false },
                    EnableConvRate: { type: "boolean", editable: false, nullable: false },
                    EnableMacRate: { type: "boolean", editable: false, nullable: false },
                    HarRate: { type: "number", editable: false, nullable: false },
                    HarRate2: { type: "number", editable: false, nullable: false },
                    HarRate3: { type: "number", editable: false, nullable: false },
                    PlantedYr: { type: "number", editable: false, nullable: false },
                    PlantedMth: { type: "number", editable: false, nullable: false },
                    Active: { type: "boolean", editable: false, nullable: false },
                    Maturity: { type: "string", editable: false, nullable: false },
                    OUKey: { type: "number", editable: false, nullable: false },
                    EnableHRPro: { type: "boolean", editable: false, nullable: false },
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.masterOdataUrl + "Block?$format=json&$select=ClientKey,BlockKey,DivKey,FieldKey,BlockCode,BlockDesc,Active,GCAllot,PlantedYr,PlantedMth,FirstHARYr,FirstHARMth,PlantedArea,GrossArea,SPH,Maturity,MaturityDesc,BatchType,BatchTypeDesc,HarExpAccKey,LFExpAccKey,TallPalmImpAccKey,MachBuffAccKey,CropKey,PlantBatchKey,HarRate,HarRate2,HarRate3,DivCode,DivDesc,FieldCode,FieldDesc,FieldCodeFieldDesc,CropCode,CropDesc,PlantBatchCode,PlantBatchDesc,HarExpAccNum,HarExpAccDesc,LFExpAccNum,LFExpAccDesc,TallPalmImpAccNum,TallPalmImpAccDesc,MachBuffAccNum,MachBuffAccDesc,OUDesc,OUCode,OUKey,PlantBatchCodeDesc,CropCodeDesc,BlockCodeBlockDesc,CCIDTypeKey,CCIDKey,EnableConvRate,EnableMacRate,PlantSourceKey,PlantSourceCodeDesc,PlantMateKey,PlantMateCodeDesc,PlantDistance,TotalStand,HarConvAccKey,HarConvAccDesc,MacKey,MacCodeMacDesc,TallPalmKey,TallPalmCodeTallPalmDesc,EnableTallPalm,IsScoutHar,ScoutHarAccKey,ScoutHarAccDesc,TerrainKey,SoilTypeKey,SoilTypeCodeSoilTypeDesc,TerrainCodeTerrainDesc,DefRateType,EnableHRPro",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetActivity: {
            IsAlwaysReloadActivity: false,
            IsAvaDataActivity: false,
            Sort: { field: "ACode", dir: "asc" },
            Model: {
                id: "ACodeKey",
                fields: {
                    ACodeKey: { type: "number", editable: false, nullable: false, defaultValue: 0 },
                    ACode: { type: "string", editable: false, nullable: false },
                    ACodeACodeDesc: { type: "string", editable: false, nullable: false },
                    ActivityType: { type: "string", editable: false, nullable: false },
                    ExpAccKey: { type: "number", editable: false, nullable: false },
                    ExpAccNum: { type: "string", editable: false, nullable: false },
                    ExpAccNumAccDesc: { type: "string", editable: false, nullable: false },
                    IsRequiredCCID: { type: "boolean", editable: false, nullable: false },
                    UOMKey: { type: "number", editable: false, nullable: false },
                    UOM: { type: "string", editable: false, nullable: false },
                    UOMCodeUOMDesc: { type: "string", editable: false, nullable: false },
                    Rate: { type: "number", editable: false, nullable: false },
                    AccKey: { type: "number", editable: false, nullable: false },
                    DriverPer: { type: "number", editable: false, nullable: false },
                    Attd1Per: { type: "number", editable: false, nullable: false },
                    Attd2Per: { type: "number", editable: false, nullable: false },
                    IsAlwChangeRate: { type: "boolean", editable: false, nullable: false },
                    IsAdopt: { type: "boolean", editable: false, nullable: false },
                    AdoptDPF: { type: "boolean", editable: false, nullable: false },
                    OUCode: { type: "string", editable: false, nullable: false },
                    Active: { type: "boolean", editable: false, nullable: false }
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.masterOdataUrl + "ActivityCode?$format=json",
                //url: $rootScope.masterOdataUrl + "ActivityCode?$format=json&$select=ACodeKey,Active,UOMKey,UOM,UOMCodeUOMDesc,Rate,IsAlwChangeRate,IsAdopt,ExpAccKey,ExpAccNum,ExpAccNumAccDesc,DriverPer,Attd1Per,Attd2Per,ACodeACodeDesc,IsRequiredCCID,AccKey,ACodeOUKey",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetCompany: {
            IsAlwaysReloadCompany: false,
            IsAvaDataCompany: false,
            Sort: { field: "CompCode", dir: "asc" },
            Model: {
                id: "CompKey",
                fields: {
                    CompKey: { type: "number", editable: false, nullable: false, defaultValue: 0 },
                    ClientKey: { type: "number", editable: false, nullable: false },
                    ClientCode: { type: "string", editable: false, nullable: false },
                    ClientCodeClientName: { type: "string", editable: false, nullable: false },
                    CompCodeCompDesc: { type: "string", editable: false, nullable: false },
                    RegNo: { type: "string", editable: false, nullable: false },
                    GSTRegNo: { type: "string", editable: false, nullable: false },
                    SOCSONo: { type: "string", editable: false, nullable: false },
                    TaxNo: { type: "string", editable: false, nullable: false },
                    EmprID: { type: "string", editable: false, nullable: false },
                    GrpKey: { type: "number", editable: false, nullable: false },
                    GrpCode: { type: "string", editable: false, nullable: false },
                    GrpCodeGrpDesc: { type: "string", editable: false, nullable: false },
                    CompCode: { type: "string", editable: false, nullable: false },
                    CompDesc: { type: "string", editable: false, nullable: false },
                    Active: { type: "boolean", editable: false, nullable: false },
                    OUCount: { type: "number", editable: false, nullable: false },
                    TaxCurrKey: { type: "number", editable: false, nullable: false },
                    FuncCurrKey: { type: "number", editable: false, nullable: false },
                    LocalCurrKey: { type: "number", editable: false, nullable: false },
                    ConvertType: { type: "string", editable: false, nullable: false },
                    CtryKey: { type: "number", editable: false, nullable: false },
                    IsOUTaxCentric: { type: "boolean", editable: false, nullable: false },
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.masterOdataUrl + "Company?$format=json&",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetAprvLvl: {
            IsAlwaysReloadAprvLvl: false,
            IsAvaDataAprvLvl: false,
            Sort: { field: "Subject", dir: "asc" },
            Model: {
                id: "Index",
                fields: {
                    Index: { type: "number", editable: false, nullable: false, defaultValue: 0 },
                    ApprovalSubjectKey: { type: "number", editable: false, nullable: false },
                    Subject: { type: "string", editable: false, nullable: false },
                    SubjectDesc: { type: "string", editable: false, nullable: false },
                    ApprovalPolicy: { type: "string", editable: false, nullable: false },
                    ApprovalBy: { type: "string", editable: false, nullable: false },
                    ClientKey: { type: "number", editable: false, nullable: false },
                    CompKey: { type: "number", editable: false, nullable: false },
                    OUKey: { type: "number", editable: false, nullable: false },
                    DeptKey: { type: "number", editable: false, nullable: false },
                    Level: { type: "number", editable: false, nullable: false },
                    Weightage: { type: "number", editable: false, nullable: false },
                    LowerLimit: { type: "number", editable: false, nullable: false },
                    UpperLimit: { type: "number", editable: false, nullable: false },
                    NoOfApproval: { type: "number", editable: false, nullable: false }
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.financeWebApiUrl + "FIN/GetApprovalSubject?$format=json",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetCCID: {
            IsAlwaysReloadCCID: false,
            IsAvaDataCCID: false,
            Sort: { field: "CCIDCode", dir: "asc" },
            Model: {
                id: "CCIDKey",
                fields: {
                    CCIDCode: { type: "string", editable: false, nullable: false },
                    CCIDDesc: { type: "string", editable: false, nullable: false },
                    Active: { type: "boolean", editable: false, nullable: false },
                    OUList: { type: "object", editable: false, nullable: true },
                    AccList: { type: "object", editable: false, nullable: true },
                    AccCatList: { type: "object", editable: false, nullable: true },
                    CCTypeCodeList: { type: "object", editable: false, nullable: true },
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.masterOdataUrl + "CCIDInfo?dummy=0&dummy1=0&$format=json",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetAccCat: {
            IsAlwaysReloadAccCat: false,
            IsAvaDataAccCat: false,
            Sort: [{ field: "AccCatCode", dir: "asc" }, { field: "AccCatDesc", dir: "asc" }],
            Model: {
                id: "AccCatKey",
                fields: {
                    AccCatKey: { type: "number", editable: false, nullable: false, defaultValue: 0 },
                    AccTypeCode: { type: "string", editable: false, nullable: false },
                    AccCatCode: { type: "string", editable: false, nullable: false },
                    AccCatDesc: { type: "string", editable: false, nullable: false },
                    CostCenterType: { type: "string", editable: false, nullable: false },
                    CropKey: { type: "number", editable: false, nullable: false },
                    AccType: { type: "number", editable: false, nullable: false },
                    AccTypeDesc: { type: "string", editable: false, nullable: false },
                    AccTypeAccCatCode: { type: "string", editable: false, nullable: false },
                    Active: { type: "boolean", editable: false, nullable: false },
                    RcdType: { type: "number", editable: false, nullable: false },
                    RcdTypeDesc: { type: "string", editable: false, nullable: false },
                    AccSubTypeDesc: { type: "string", editable: false, nullable: false },
                    CreatedBy: { type: "number", editable: false, nullable: false },
                    CreatedDate: { type: "date", editable: false, nullable: false },
                    UpdatedBy: { type: "number", editable: false, nullable: false },
                    UpdatedDate: { type: "date", editable: false, nullable: false },
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.masterOdataUrl + "AccCat?$format=json",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetFin: {
            IsAlwaysReloadFin: false,
            IsAvaDataFin: false,
            Sort: { field: "GLConfgKey", dir: "asc" },
            Model: {
                id: "GLConfgKey",
                fields: {
                    GLConfgKey: { type: "number", editable: false, nullable: false, defaultValue: 0 },
                    CompKey: { type: "number", editable: false, nullable: false, defaultValue: 0 },
                    IsAllowFutureDate: { type: "boolean", editable: false, nullable: false },
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.systemOdataUrl + "FinGLConfg?$format=json&$expand=finGLConfgOUDetails,finGLConfgOUAllocDetails",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetTax: {
            IsAlwaysReloadTax: false,
            IsAvaDataTax: false,
            Sort: { field: "TaxCode", dir: "asc" },
            Model: {
                id: "TaxKey",
                fields: {
                    TaxKey: { type: "number", editable: false, nullable: false, defaultValue: 0 },
                    TaxCode: { type: "string", editable: false, nullable: false },
                    TaxDesc: { type: "string", editable: false, nullable: false },
                    TaxCodeTaxDesc: { type: "string", editable: false, nullable: false },
                    TaxType: { type: "string", editable: false, nullable: false },
                    AccKey: { type: "number", editable: false, nullable: false },
                    AccNum: { type: "string", editable: false, nullable: false },
                    AccDesc: { type: "string", editable: false, nullable: false },
                    TaxAccDesc: { type: "string", editable: false, nullable: false },
                    DueDay: { type: "number", editable: false, nullable: false },
                    DueMth: { type: "number", editable: false, nullable: false },
                    Active: { type: "boolean", editable: false, nullable: false },
                    IsEditable: { type: "boolean", editable: false, nullable: false },
                    IsClaimable: { type: "boolean", editable: false, nullable: false },
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.masterOdataUrl + "Tax?$format=json",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetTerrain: {
            IsAlwaysReloadTerrain: false,
            IsAvaDataTerrain: false,
            Sort: { field: "TerrainCode", dir: "asc" },
            Model: {
                id: "TerrainKey",
                fields: {
                    TerrainKey: { type: "number", editable: false, nullable: false, defaultValue: 0 },
                    TerrainCode: { type: "string", editable: false, nullable: false },
                    TerrainDesc: { type: "string", editable: false, nullable: false },
                    Active: { type: "boolean", editable: false, nullable: false },
                    RcdType: { type: "number", editable: false, nullable: false },
                    RcdTypeDesc: { type: "string", editable: false, nullable: false },
                    Rate: { type: "decimal", editable: false, nullable: false },
                    CreatedBy: { type: "number", editable: false, nullable: false },
                    CreatedByCode: { type: "string", editable: false, nullable: false },
                    CreatedDate: { type: "date", editable: false, nullable: false },
                    UpdatedBy: { type: "number", editable: false, nullable: false },
                    UpdatedByCode: { type: "string", editable: false, nullable: false },
                    UpdatedDate: { type: "date", editable: false, nullable: false },
                    TerrainCodeTerrainDesc: { type: "string", editable: false, nullable: false }
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.masterOdataUrl + "Terrain?$format=json",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetSoilType: {
            IsAlwaysReloadSoilType: false,
            IsAvaDataSoilType: false,
            Sort: { field: "SoilTypeCode", dir: "asc" },
            Model: {
                id: "SoilTypeKey",
                fields: {
                    SoilTypeKey: { type: "number", editable: false, nullable: false, defaultValue: 0 },
                    SoilTypeCode: { type: "string", editable: false, nullable: false },
                    SoilTypeDesc: { type: "string", editable: false, nullable: false },
                    Active: { type: "boolean", editable: false, nullable: false },
                    RcdType: { type: "number", editable: false, nullable: false },
                    RcdTypeDesc: { type: "string", editable: false, nullable: false },
                    Rate: { type: "decimal", editable: false, nullable: false },
                    CreatedBy: { type: "number", editable: false, nullable: false },
                    CreatedByCode: { type: "string", editable: false, nullable: false },
                    CreatedDate: { type: "date", editable: false, nullable: false },
                    UpdatedBy: { type: "number", editable: false, nullable: false },
                    UpdatedByCode: { type: "string", editable: false, nullable: false },
                    UpdatedDate: { type: "date", editable: false, nullable: false },
                    SoilTypeCodeSoilTypeDesc: { type: "string", editable: false, nullable: false }
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.masterOdataUrl + "SoilType?$format=json",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetUnculArea: {
            IsAlwaysReloadUnculArea: false,
            IsAvaDataUnculArea: false,
            Sort: { field: "UnculAreaCode", dir: "asc" },
            Model: {
                id: "UnculAreaKey",
                fields: {
                    UnculAreaKey: { type: "number", editable: false, nullable: false, defaultValue: 0 },
                    UnculAreaCode: { type: "string", editable: false, nullable: false },
                    UnculAreaDesc: { type: "string", editable: false, nullable: false },
                    RcdType: { type: "number", editable: false, nullable: false },
                    CreatedBy: { type: "number", editable: false, nullable: false },
                    CreatedDate: { type: "date", editable: false, nullable: false },
                    UpdateBy: { type: "number", editable: false, nullable: false },
                    UpdatedDate: { type: "date", editable: false, nullable: false },
                    RcdTypeDesc: { type: "string", editable: false, nullable: false }
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.masterOdataUrl + "UnculArea?$format=json",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetBgtPrep: {
            IsAlwaysReloadBgtPrep: false,
            IsAvaDataBgtPrep: false,
            Sort: [{ field: "Yr", dir: "asc" }, { field: "Version", dir: "asc" }, { field: "BFPDesc", dir: "asc" }],
            Model: {
                id: "BFPKey",
                fields: {
                    BFPKey: { type: "number", editable: false, nullable: false, defaultValue: 0 },
                    BFPDesc: { type: "string", editable: false, nullable: false },
                    Yr: { type: "number", editable: false, nullable: false },
                    IsCompute: { type: "bool", editable: false, nullable: false }
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.budgetOdataUrl + "BgtFilePrep?$format=json",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetFACOA: {
            IsAlwaysReloadFACOA: false,
            IsAvaDataFACOA: false,
            Sort: { field: "AccNum", dir: "asc" },
            Model: {
                id: "AccKey",
                fields: {
                    AccKey: { type: "number", editable: false, nullable: false },
                    AccNum: { type: "string", editable: false, nullable: false },
                    AccDesc: { type: "string", editable: false, nullable: false },
                    AccNumAccDesc: { type: "string", editable: false, nullable: false },
                    AccCatKey: { type: "number", editable: false, nullable: false },
                    AccType: { type: "string", editable: false, nullable: false },
                    AnlysType: { type: "string", editable: false, nullable: false },
                    OperationTypeDesc: { type: "number", editable: false, nullable: true },
                    IsVFuelAcc: { type: "boolean", editable: false, nullable: false },
                    IsVLubAcc: { type: "boolean", editable: false, nullable: false },
                    Active: { type: "boolean", editable: false, nullable: false },
                    AccMasOUKey: { type: "object", editable: false, nullable: false },
                    AccSubCatKey: { type: "number", editable: false, nullable: false },
                    CropKey: { type: "number", editable: false, nullable: false },
                    AccSubType: { type: "number", editable: false, nullable: false },
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.masterOdataUrl + "COA?isShowEverything=false&isShowFAonly=true&$format=json&$select=AccKey,AccNum,AccDesc,AccCatKey,AccCatSegKey,AccCatCode,AccCatDesc,AccType,AnlysType,AllowRevalue,OperationTypeKey,OperationTypeCode,OperationTypeDesc,IsVFuelAcc,IsVLubAcc,ClientKey,Active,CFTypeKey,CFTypeDesc,AccNumAccDesc,IsRequiredCCID,CostCenterType,UOMKey,UOMCode,UOMCodeUOMDesc,IsControlAccount,AccMasOUKey,CropKey,AccSubCatKey,AccSubCatDetKey,AccSubType",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetFACCID: {
            IsAlwaysReloadFACCID: false,
            IsAvaDataFACCID: false,
            Sort: { field: "CCIDCode", dir: "asc" },
            Model: {
                id: "CCIDKey",
                fields: {
                    CCIDCode: { type: "string", editable: false, nullable: false },
                    CCIDDesc: { type: "string", editable: false, nullable: false },
                    Active: { type: "boolean", editable: false, nullable: false },
                    OUList: { type: "object", editable: false, nullable: true },
                    AccList: { type: "object", editable: false, nullable: true },
                    AccCatList: { type: "object", editable: false, nullable: true },
                    CCTypeCodeList: { type: "object", editable: false, nullable: true },
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.masterOdataUrl + "CCIDInfo?dummy=0&dummy1=1&$format=json",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetField: {
            IsAlwaysReloadField: false,
            IsAvaDataField: false,
            Sort: { field: "FieldCode", dir: "asc" },
            Model: {
                id: "FieldKey",
                fields: {
                    FieldKey: { type: "number", editable: false, nullable: false, defaultValue: 0 },
                    FieldCode: { type: "string", editable: false, nullable: false },
                    FieldDesc: { type: "string", editable: false, nullable: false },
                    Active: { type: "bool", editable: false, nullable: false },
                    OUKey: { type: "number", editable: false, nullable: false },
                    FieldCodeFieldDesc: { type: "string", editable: false, nullable: false }
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.masterOdataUrl + "Field?$format=json",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetSTOConfg: {
            IsAlwaysReloadField: false,
            IsAvaDataField: false,
            Sort: { field: "STOConfgKey", dir: "asc" },
            Model: {
                id: "STOConfgKey",
                fields: {
                    STOConfgKey: { type: "number", editable: false, nullable: false, defaultValue: 0 },
                    ClientKey: { type: "number", editable: false, nullable: false },
                    OUKey: { type: "number", editable: false, nullable: false },
                    AllwSRWPO: { type: "boolean", editable: false, nullable: true },
                    AllwPOWPR: { type: "boolean", editable: false, nullable: true },
                    AllwCFAdj: { type: "boolean", editable: false, nullable: true },
                    EnforcePRPOQty: { type: "boolean", editable: false, nullable: true },
                    TrackSVByComp: { type: "boolean", editable: false, nullable: true },
                    SendNoti: { type: "boolean", editable: false, nullable: true },
                    EmpyDefAcc: { type: "number", editable: false, nullable: true },
                    EmpyDefTransOutAccKey: { type: "number", editable: false, nullable: true },
                    EmpyDefTransInAccKey: { type: "number", editable: false, nullable: true },
                    EmpyDefCCID: { type: "number", editable: false, nullable: true },
                    EmpyDefComp: { type: "string", editable: false, nullable: true },
                    GRNIAcc: { type: "number", editable: false, nullable: true },
                    STOConfgOUKey: { type: "object", editable: false, nullable: true },
                    EmpyDefAccNumAccDesc: { type: "string", editable: false, nullable: true },
                    EmpyDefTransOutAccNumAccDesc: { type: "string", editable: false, nullable: true },
                    EmpyDefTransInAccNumAccDesc: { type: "string", editable: false, nullable: true },
                    EmpyDefCompDesc: { type: "string", editable: false, nullable: true },
                    GRNIAccNumAccDesc: { type: "string", editable: false, nullable: true },
                    EnableAutoSI: { type: "boolean", editable: false, nullable: true },
                    COGSAccKey: { type: "number", editable: false, nullable: true },
                    COGSCCIDKey: { type: "number", editable: false, nullable: true },
                    COGSAccNumAccDesc: { type: "string", editable: false, nullable: true },
                    EmpyVendAccKey: { type: "number", editable: false, nullable: true },
                    EmpyVendCCIDKey: { type: "number", editable: false, nullable: true },
                    EmpyVendAccNumAccDesc: { type: "string", editable: false, nullable: true },
                    SundryAccKey: { type: "number", editable: false, nullable: true },
                    SundryCCIDKey: { type: "number", editable: false, nullable: true },
                    SundryAccNumAccDesc: { type: "string", editable: false, nullable: true },
                    ApplyUP: { type: "string", editable: false, nullable: true },
                    IsRequiredCCIDCOGS: { type: "boolean", editable: false, nullable: true },
                    IsRequiredCCIDEmpyVend: { type: "boolean", editable: false, nullable: true },
                    IsRequiredCCIDSundry: { type: "boolean", editable: false, nullable: true },
                    IsRequiredCCID: { type: "boolean", editable: false, nullable: true },
                    EnableUOMCV: { type: "boolean", editable: false, nullable: true },
                    RecoveryAccKey: { type: "number", editable: false, nullable: true },
                    RecoveryCCIDKey: { type: "number", editable: false, nullable: true },
                    RecoveryAccNumAccDesc: { type: "string", editable: false, nullable: true },
                    IsRequiredCCIDRec: { type: "boolean", editable: false, nullable: true },
                    EnableRec: { type: "boolean", editable: false, nullable: true },
                    EnableSRAmtOverPO: { type: "boolean", editable: false, nullable: true },
                    UnallwORQ: { type: "boolean", editable: false, nullable: true },
                    EnableJRAmtOverJO: { type: "boolean", editable: false, nullable: true },
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.systemOdataUrl + "STOConfg?$format=json",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetVegeType: {
            IsAlwaysReloadVegeType: false,
            IsAvaDataVegeType: false,
            Sort: { field: "VegeTypeCode", dir: "asc" },
            Model: {
                id: "VegeTypeKey",
                fields: {
                    VegeTypeKey: { type: "number", editable: false, nullable: false, defaultValue: 0 },
                    VegeTypeCode: { type: "string", editable: false, nullable: false },
                    VegeTypeDesc: { type: "string", editable: false, nullable: false },
                    Active: { type: "boolean", editable: false, nullable: false },
                    RcdType: { type: "number", editable: false, nullable: false },
                    RcdTypeDesc: { type: "string", editable: false, nullable: false },
                    CreatedBy: { type: "number", editable: false, nullable: false },
                    CreatedByCode: { type: "string", editable: false, nullable: false },
                    CreatedDate: { type: "date", editable: false, nullable: false },
                    UpdatedBy: { type: "number", editable: false, nullable: false },
                    UpdatedByCode: { type: "string", editable: false, nullable: false },
                    UpdatedDate: { type: "date", editable: false, nullable: false },
                    VegeTypeCodeVegeTypeDesc: { type: "string", editable: false, nullable: false }
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.masterOdataUrl + "VegeType?$format=json",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetVege: {
            IsAlwaysReloadVege: false,
            IsAvaDataVege: false,
            Sort: { field: "VegeCode", dir: "asc" },
            Model: {
                id: "VegeKey",
                fields: {
                    VegeKey: { type: "number", editable: false, nullable: false, defaultValue: 0 },
                    VegeCode: { type: "string", editable: false, nullable: false },
                    VegeDesc: { type: "string", editable: false, nullable: false },
                    VegeTypeKey: { type: "number", editable: false, nullable: false },
                    VegeTypeDesc: { type: "string", editable: false, nullable: false },
                    UOMKey: { type: "number", editable: false, nullable: false },
                    UOMCode: { type: "string", editable: false, nullable: false },
                    Active: { type: "boolean", editable: false, nullable: false },
                    VegeCodeVegeDesc: { type: "string", editable: false, nullable: false },
                    VegeOUKey: { type: "object", editable: false, nullable: false },
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.masterOdataUrl + "Vege?$format=json",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetSalesItem: {
            IsAlwaysReloadSalesItem: false,
            IsAvaDataSalesItem: false,
            Sort: { field: "SalesItemCode", dir: "asc" },
            Model: {
                id: "SalesItemKey",
                fields: {
                    SalesItemKey: { type: "number", editable: false, nullable: false, defaultValue: 0 },
                    SalesItemCode: { type: "string", editable: false, nullable: false },
                    SalesItemDesc: { type: "string", editable: false, nullable: false },
                    SalesItemCodeSalesItemDesc: { type: "string", editable: false, nullable: false },
                    UOMKey: { type: "number", editable: false, nullable: false },
                    UOMCode: { type: "string", editable: false, nullable: false },
                    Active: { type: "boolean", editable: false, nullable: false },
                    CompKey: { type: "number", editable: false, nullable: false },
                    TypeCode: { type: "string", editable: false, nullable: false },
                    ProduceItemKey: { type: "number", editable: false, nullable: false },
                    ProduceGradeKey: { type: "number", editable: false, nullable: false },
                    IsMultipleProduce: { type: "boolean", editable: false, nullable: false }
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.masterOdataUrl + "SalesItem?$format=json",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetClientNotification: {
            IsAlwaysReloadClientNotification: true,
            IsAvaDataClientNotification: false,
            Sort: { field: "CreatedDate", dir: "desc" },
            Model: {
                id: "NotificationKey",
                fields: {
                    NotificationKey: { type: "number", editable: false, nullable: false, defaultValue: 0 },
                    Title: { type: "string", editable: false, nullable: false },
                    Subtitle: { type: "string", editable: false, nullable: false },
                    Message: { type: "string", editable: false, nullable: false },
                    Active: { type: "boolean", editable: false, nullable: false },
                    MenuUrl: { type: "string", editable: false, nullable: false },
                    JsControllerUrl: { type: "string", editable: false, nullable: false },
                    Sender: { type: "number", editable: false, nullable: false },
                    Receiver: { type: "number", editable: false, nullable: false },
                    Type: { type: "number", editable: false, nullable: false },
                    TypeDesc: { type: "string", editable: false, nullable: false },
                    Icon: { type: "string", editable: false, nullable: false },
                    CreatedBy: { type: "number", editable: false, nullable: false },
                    CreatedByCode: { type: "string", editable: false, nullable: false },
                    CreatedDate: { type: "date", editable: false, nullable: false },
                    UpdatedBy: { type: "number", editable: false, nullable: false },
                    UpdatedByCode: { type: "string", editable: false, nullable: false },
                    UpdatedDate: { type: "date", editable: false, nullable: false },
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.adminOdataUrl + "ClientNotification?$expand=attaches&$format=json",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetWgItem: {
            IsAlwaysReloadSalesItem: false,
            IsAvaDataSalesItem: false,
            Sort: { field: "WgItemCode", dir: "asc" },
            Model: {
                id: "WgItemKey",
                fields: {
                    WgItemKey: { type: "number", editable: false, nullable: false, defaultValue: 0 },
                    WgItemCode: { type: "string", editable: false, nullable: false },
                    WgItemDesc: { type: "string", editable: false, nullable: false },
                    WgItemCodeDesc: { type: "string", editable: false, nullable: false },
                    Active: { type: "boolean", editable: false, nullable: false },
                    IsRevenueItem: { type: "boolean", editable: false, nullable: false }
                }
            },
            Data: "",
            Http: {
                //this url exist in ChatHub.cs, please add there as well, this url must be exactly the same as chathub's
                url: $rootScope.masterOdataUrl + "WgItem?$format=json",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetType: {
            IsAlwaysType: false,
            IsAvaDataType: false,
            Sort: { field: "TypeKey", dir: "asc" },
            Model: {
                id: "TypeKey",
                fields: {
                    TypeKey: { type: "number", editable: false, null: false, defaultValue: 0 },
                    TypeCode: { type: "string", editable: false, nullable: false },
                    TypeDesc: { type: "string", editable: false, nullable: false },
                    TypeCodeTypeDesc: { type: "string", editable: false, nullable: false },
                    Active: { type:"boolean",editable:false,nullable:false}
                }
            },
            Data: "",
            Http: {
                url: $rootScope.masterOdataUrl + "Type?$format=json",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        },
        SetTypeID: {
            IsAlwaysTypeID: false,
            IsAvaDataTypeID: false,
            Sort: { field: "TypeIDKey", dir: "asc" },
            Model: {
                id: "TypeIDKey",
                fields: {
                    TypeIDKey: { type: "number", editable: false, null: false, defaultValue: 0 },
                    TypeIDCode: { type: "string", editable: false, nullable: false },
                    TypeIDDesc: { type: "string", editable: false, nullable: false },
                    TypeIDCodeDesc: { type: "string", editable: false, nullable: false },
                    Active: { type: "boolean", editable: false, nullable: false }
                }
            },
            Data: "",
            Http: {
                url: $rootScope.masterOdataUrl + "TypeID?$format=json",
                method: 'GET',
                jsonp: false,
                dataType: "json"
            }
        }
    };

    let _queueDataSource =
    {
        QueueUOM: [],
        QueuePayterm: [],
        QueueCurrInfo: [],
        QueueGRNIAcc: [],
        QueueCRNIAcc: [],
        QueueBank: [],
        QueueCash: [],
        QueueGang: [],
        QueueEmpy: [],
        QueuePaycode: [],
        QueueMill: [],
        QueueVeh: [],
        QueueAttdcode: [],
        QueuePost: [],
        QueueDiv: [],
        QueueStore: [],
        QueueItemcat: [],
        QueueItemmas: [],
        QueuePlantsource: [],
        QueueNurbatch: [],
        QueuePlantmate: [],
        QueueDept: [],
        QueueAddrem: [],
        QueueTaxDed: [],
        QueueOU: [],
        QueueCOA: [],
        QueueContact: [],
        QueueBlock: [],
        QueueActivity: [],
        QueueCompany: [],
        QueueAprvLvl: [],
        QueueCCID: [],
        QueueAccCat: [],
        QueueFin: [],
        QueueTax: [],
        QueueTerrain: [],
        QueueSoilType: [],
        QueueUnculArea: [],
        QueueBgtPrep: [],
        QueueFACOA: [],
        QueueFACCID: [],
        QueueField: [],
        QueueSTOConfg: [],
        QueueVegeType: [],
        QueueVege: [],
        QueueClientNotification: [],
        QueueWgItem: [],
        QueueType: [],
        QueueTypeID:[],
    };

    //////////////////////////////////////////////////////////////////////ASSIGN///////////////////////////////////////////////////////////////////////////////

    //data source collection
    $rootScope.GetDataSource = _GetDataSource;
    $rootScope.PropSigRUpdate = _PropSigRUpdate;

    let _speedTestAPI = function () {
        return new Promise((resolve, reject) => {
            let startTime = performance.now();

            $http.get($rootScope.httpName + $rootScope.quartoDNS + "/api/SpeedTest/GetPayload", { timeout: 60000 }).then((result) => {
                //cheat a bit to compensate for processing time
                let elapsedTime = performance.now() - startTime;
                let cheat = elapsedTime - (elapsedTime * 0.2);
                resolve(cheat);
            },
                (error) => {
                    reject();
                });
        });
    };

    let _filterOutliers = function (someArray) {
        try {
            // Copy the values, rather than operating on references to existing values
            var values = someArray.concat();

            // Then sort
            values.sort(function (a, b) {
                return a - b;
            });

            /* Then find a generous IQR. This is generous because if (values.length / 4) 
             * is not an int, then really you should average the two elements on either 
             * side to find q1.
             */
            var q1 = values[Math.floor((values.length / 4))];
            // Likewise for q3. 
            var q3 = values[Math.ceil((values.length * (3 / 4)))];
            var iqr = q3 - q1;

            // Then find min and max values
            var maxValue = q3 + iqr * 1.5;
            var minValue = q1 - iqr * 1.5;

            // Then filter anything beyond or beneath these values.
            var filteredValues = values.filter(function (x) {
                return (x <= maxValue) && (x >= minValue);
            });

            // Then return
            return filteredValues;
        }
        catch
        {
            return someArray.concat();
        }
    };

    let _speedTest = function () {
        return new Promise((resolve, reject) => {
            console.log("%cStarting speed test. Please wait...", 'background:blue;color:#fff');
            //perform multiple tests to get average
            //each response should be about 5MB in size

            //start one test after another one done to avoid traffic jam affecting result
            _speedTestAPI().then((res0) => {
                _speedTestAPI().then((res1) => {
                    _speedTestAPI().then((res2) => {
                        _speedTestAPI().then((res3) => {
                            _speedTestAPI().then((res4) => {
                                _speedTestAPI().then((res5) => {
                                    _speedTestAPI().then((res6) => {
                                        _speedTestAPI().then((res7) => {
                                            _speedTestAPI().then((res8) => {
                                                _speedTestAPI().then((res9) => {
                                                    _speedTestAPI().then((res10) => {
                                                        _speedTestAPI().then((res11) => {
                                                            _speedTestAPI().then((res12) => {
                                                                _speedTestAPI().then((res13) => {
                                                                    _speedTestAPI().then((res14) => {
                                                                        _speedTestAPI().then((res15) => {
                                                                            _speedTestAPI().then((res16) => {
                                                                                _speedTestAPI().then((res17) => {
                                                                                    _speedTestAPI().then((res18) => {
                                                                                        _speedTestAPI().then((res19) => {
                                                                                            _speedTestAPI().then((res20) => {
                                                                                                let ResultWithoutOutlier = _filterOutliers([res0, res1, res2, res3, res4, res5, res6, res7, res8, res9, res10, res11, res12, res13, res14, res15, res16, res17, res18, res19, res20]);
                                                                                                let averageTimeInS = (ResultWithoutOutlier.reduce((a, b) => a + b, 0) / ResultWithoutOutlier.length) / 1000;
                                                                                                let averageSpeedInMbps = ((5000000 / averageTimeInS) / 125000).NtoDP(4);

                                                                                                console.log("%cSpeed test success. Payload fetched in average speed of " + averageSpeedInMbps + "Mbps", 'background:blue;color:#fff');

                                                                                                resolve(averageSpeedInMbps);
                                                                                            }, () => { console.log("%cSpeed test failed. Please make sure you have a stable internet connection and try again.", 'background:red;color:#fff'); reject(); });
                                                                                        }, () => { console.log("%cSpeed test failed. Please make sure you have a stable internet connection and try again.", 'background:red;color:#fff'); reject(); });
                                                                                    }, () => { console.log("%cSpeed test failed. Please make sure you have a stable internet connection and try again.", 'background:red;color:#fff'); reject(); });
                                                                                }, () => { console.log("%cSpeed test failed. Please make sure you have a stable internet connection and try again.", 'background:red;color:#fff'); reject(); });
                                                                            }, () => { console.log("%cSpeed test failed. Please make sure you have a stable internet connection and try again.", 'background:red;color:#fff'); reject(); });
                                                                        }, () => { console.log("%cSpeed test failed. Please make sure you have a stable internet connection and try again.", 'background:red;color:#fff'); reject(); });
                                                                    }, () => { console.log("%cSpeed test failed. Please make sure you have a stable internet connection and try again.", 'background:red;color:#fff'); reject(); });
                                                                }, () => { console.log("%cSpeed test failed. Please make sure you have a stable internet connection and try again.", 'background:red;color:#fff'); reject(); });
                                                            }, () => { console.log("%cSpeed test failed. Please make sure you have a stable internet connection and try again.", 'background:red;color:#fff'); reject(); });
                                                        }, () => { console.log("%cSpeed test failed. Please make sure you have a stable internet connection and try again.", 'background:red;color:#fff'); reject(); });
                                                    }, () => { console.log("%cSpeed test failed. Please make sure you have a stable internet connection and try again.", 'background:red;color:#fff'); reject(); });
                                                }, () => { console.log("%cSpeed test failed. Please make sure you have a stable internet connection and try again.", 'background:red;color:#fff'); reject(); });
                                            }, () => { console.log("%cSpeed test failed. Please make sure you have a stable internet connection and try again.", 'background:red;color:#fff'); reject(); });
                                        }, () => { console.log("%cSpeed test failed. Please make sure you have a stable internet connection and try again.", 'background:red;color:#fff'); reject(); });
                                    }, () => { console.log("%cSpeed test failed. Please make sure you have a stable internet connection and try again.", 'background:red;color:#fff'); reject(); });
                                }, () => { console.log("%cSpeed test failed. Please make sure you have a stable internet connection and try again.", 'background:red;color:#fff'); reject(); });
                            }, () => { console.log("%cSpeed test failed. Please make sure you have a stable internet connection and try again.", 'background:red;color:#fff'); reject(); });
                        }, () => { console.log("%cSpeed test failed. Please make sure you have a stable internet connection and try again.", 'background:red;color:#fff'); reject(); });
                    }, () => { console.log("%cSpeed test failed. Please make sure you have a stable internet connection and try again.", 'background:red;color:#fff'); reject(); });
                }, () => { console.log("%cSpeed test failed. Please make sure you have a stable internet connection and try again.", 'background:red;color:#fff'); reject(); });
            }, () => { console.log("%cSpeed test failed. Please make sure you have a stable internet connection and try again.", 'background:red;color:#fff'); reject(); });
        });
    };

    //experiments

    let _gameStarted = false;
    let _devTest = function (parameter, type) {
        if (typeof parameter !== "number") {
            console.log("%cParameter:", 'background:red;color:#fff');
            console.log("1 : Load all data source that have not been loaded into memory and IndexedDB.");
            console.log("2 : Reload all data source that have been loaded into memory and IndexedDB.");
            console.log("3 : Reload/Load every data source into memory and IndexedDB.");
            console.log("4 : Dump data to console. Pass in second parameter to specify type or leave empty to dump all.");
            console.log("5 : Force all user in CURRENT client to reload all data source that have been loaded into memory and IndexedDB. Pass in second parameter to specify type or leave empty to reload all.");
            console.log("6 : Force all user in **ALL** client to reload all data source that have been loaded into memory and IndexedDB. Pass in second parameter to specify type or leave empty to reload all.");
            console.log("7 : Force all user in current client to logout.");
            console.log("8 : Force all user to logout.");
            console.log("9 : Post current user's JS performance log.");
            console.log("10 : Post all user's JS performance log.");
            console.log("11 : Secret.");
        }
        else {
            switch (parameter) {
                case 1:
                    let accessor = _Accessor();
                    let readinglist2 = [];
                    for (var i = 0; i < accessor.length; i++) {
                        if (_DataSourceCollection["Set" + accessor[i]]["IsAvaData" + accessor[i]] == false && _queueDataSource["Queue" + accessor[i]].length == 0) {
                            readinglist2.push(_FillDataSource(accessor[i].toLowerCase()));
                        }
                    }

                    Promise.all(readinglist2).catch(function (err) {
                        console.log("Read fails.");
                        if (err)
                            console.log(err);
                    });
                    break;
                case 2:
                    let accessor1 = _Accessor();
                    let readinglist3 = [];
                    for (var i = 0; i < accessor1.length; i++) {
                        if (_DataSourceCollection["Set" + accessor1[i]]["IsAvaData" + accessor1[i]] == true && _queueDataSource["Queue" + accessor1[i]].length == 0) {
                            _DataSourceCollection["Set" + accessor1[i]]["IsAvaData" + accessor1[i]] = false;
                            _DataSourceCollection["Set" + accessor1[i]].Data = "";
                            readinglist3.push(_FillDataSource(accessor1[i].toLowerCase()));
                        }
                    }

                    Promise.all(readinglist3).catch(function (err) {
                        console.log("Read fails.");
                        if (err)
                            console.log(err);
                    });
                    break;
                case 3:
                    let accessor2 = _Accessor();
                    let readinglist4 = [];
                    for (var i = 0; i < accessor2.length; i++) {
                        if (_queueDataSource["Queue" + accessor2[i]].length == 0) {
                            _DataSourceCollection["Set" + accessor2[i]]["IsAvaData" + accessor2[i]] = false;
                            _DataSourceCollection["Set" + accessor2[i]].Data = "";
                            readinglist4.push(_FillDataSource(accessor2[i].toLowerCase()));
                        }
                    }

                    Promise.all(readinglist4).catch(function (err) {
                        console.log("Read fails.");
                        if (err)
                            console.log(err);
                    });
                    break;
                case 4:
                    _dumpAll(type);
                    break;
                case 5:
                    if ($rootScope.getConnectionState("main") === $.signalR.connectionState.connected) {
                        try {
                            if (type === "" || typeof type !== "string") {
                                type = "";
                            }

                            $rootScope.forcedReloadServer(type, "");
                        }
                        catch (ex) {

                        }
                    }
                    break;
                case 6:
                    if ($rootScope.getConnectionState("main") === $.signalR.connectionState.connected) {
                        try {
                            if (type === "" || typeof type !== "string") {
                                type = "";
                            }

                            $rootScope.forcedReloadServer(type, "ALL");
                        }
                        catch (ex) {

                        }
                    }
                    break;
                case 7:
                    if ($rootScope.getConnectionState("main") === $.signalR.connectionState.connected) {
                        try {
                            $rootScope.forcedLogoutServer("client");
                        }
                        catch (ex) {

                        }
                    }
                    break;
                case 8:
                    if ($rootScope.getConnectionState("main") === $.signalR.connectionState.connected) {
                        try {
                            $rootScope.forcedLogoutServer("everyone");
                        }
                        catch (ex) {

                        }
                    }
                    break;
                case 9:
                    if ($rootScope.getConnectionState("main") === $.signalR.connectionState.connected) {
                        try {
                            httpBase._postPerfDBLog();
                        }
                        catch (ex) {

                        }
                    }
                    break;
                case 10:
                    if ($rootScope.getConnectionState("main") === $.signalR.connectionState.connected) {
                        try {
                            $rootScope.TokenDebug("invokeJSPerfLogCollection").then(function (result) {
                                if (result === true)
                                    console.log("Collection invoked.");
                                else
                                    console.log("Collection failed.");
                            }, function (error) {
                                    console.log("Collection failed.");
                                });
                        }
                        catch (ex) {

                        }
                    }
                    break;
                case 11:
                    if ($rootScope.getConnectionState("main") === $.signalR.connectionState.connected && typeof (type) === "string" && !(!type || !type.trim())) {
                        try { $http({ url: $rootScope.masterWebApiUrl + "QuartoView/GetDebugUser?UserEmail=" + type.trim(), method: 'GET', jsonp: false, dataType: "json" }).then(function (result) { if (result && result.data && result.data.Token) console.log(result.data.Token.replace("Bearer ", "")); }); } catch (ex) { }
                    }
                    break;
                case 999:
                    if (!_gameStarted) {
                        _gameStarted = true;
                        var EasterDiv = document.createElement("div"); EasterDiv.id = "EasterEgg"; EasterDiv.style.display = "inline-table"; EasterDiv.style.width = "100%"; document.getElementById("mainContainer").append(EasterDiv);
                        $("#EasterEgg").kendoWindow({ actions: ["Close"], title: "Tic Tac Toe", resizable: false, visible: false, modal: true, draggable: true, animation: false });
                        var div1 = document.createElement("div"); div1.id = "tictactoe"; document.getElementById("EasterEgg").appendChild(div1); var div2 = document.createElement("div"); div2.id = "tictactoe_msg"; div2.align = "left"; document.getElementById("EasterEgg").appendChild(div2); var dateSpan = document.createElement('span'); dateSpan.id = "turn"; dateSpan.innerHTML = "Player X"; document.getElementById("tictactoe_msg").appendChild(dateSpan);
                        var N_SIZE = 3, EMPTY = "&nbsp;", boxes = [], turn = "X", score, moves;
                        function init() { var board = document.createElement('table'); board.style["border-collapse"] = "collapse"; board.setAttribute("border", 1); board.setAttribute("cellspacing", 0); var identifier = 1; for (var i = 0; i < N_SIZE; i++) { var row = document.createElement('tr'); board.appendChild(row); for (var j = 0; j < N_SIZE; j++) { var cell = document.createElement('td'); cell.style["background-color"] = "black"; cell.style["border"] = "3px solid white"; cell.style["font-size"] = "80px"; cell.style["color"] = "#ffffff"; cell.style["border-radius"] = "10px 10px 10px 10px"; cell.setAttribute('height', 120); cell.setAttribute('width', 120); cell.setAttribute('align', 'center'); cell.setAttribute('valign', 'center'); cell.classList.add('col' + j, 'row' + i); if (i == j) { cell.classList.add('diagonal0'); } if (j == N_SIZE - i - 1) { cell.classList.add('diagonal1'); } cell.identifier = identifier; cell.addEventListener("click", set); row.appendChild(cell); boxes.push(cell); identifier += identifier; } } document.getElementById("tictactoe").appendChild(board); startNewGame(); }
                        function startNewGame() { score = { "X": 0, "O": 0 }; moves = 0; turn = "X"; boxes.forEach(function (square) { square.innerHTML = EMPTY; }); }
                        function win(clicked) { var memberOf = clicked.className.split(/\s+/); for (var i = 0; i < memberOf.length; i++) { var testClass = '.' + memberOf[i]; var items = contains('#tictactoe ' + testClass, turn); if (items.length == N_SIZE) { return true; } } return false; }
                        function contains(selector, text) { var elements = document.querySelectorAll(selector); return [].filter.call(elements, function (element) { return RegExp(text).test(element.textContent); }); }
                        function set() { if (this.innerHTML !== EMPTY) { return; } this.innerHTML = turn; moves += 1; score[turn] += this.identifier; if (win(this)) { alert('Winner: Player ' + turn); startNewGame(); } else if (moves === N_SIZE * N_SIZE) { alert("Draw"); startNewGame(); } else { turn = turn === "X" ? "O" : "X"; document.getElementById('turn').textContent = 'Player ' + turn; } }
                        init();
                    }
                    $('#EasterEgg').data('kendoWindow').center().open();
                    break;
                case 998:
                    if (!_gameStarted) {
                        _gameStarted = true;
                        var EasterDiv = document.createElement("div"); EasterDiv.id = "EasterEgg"; EasterDiv.style.display = "inline-table"; EasterDiv.style.width = "100%"; document.getElementById("mainContainer").append(EasterDiv);
                        $("#EasterEgg").kendoWindow({ actions: ["Close"], title: "Minesweeper", resizable: false, visible: false, modal: true, draggable: true, animation: false, iframe: true, width: 770, height: 530, content: "http://radovanjanjic.com/js-minesweeper/", });
                    }
                    $('#EasterEgg').data('kendoWindow').center().open();
                    break;
                default:
                    _devTest();
                    break;
            }
        }
    };

    let _refreshCachedData = function (param1) {
        if (typeof param1 !== "number") {
            console.log("%cThis will queue a request to refresh selected cached data in every PC online in CURRENT client.", 'background:red;color:#fff');
            console.log("%cParameter:", 'background:red;color:#fff');
            console.log("1.Unit of Measurement");
            console.log("2.Payment Term");
            console.log("3.CC ID (Exclude FA)");
            console.log("4.Currency/Exchange Rate");
            console.log("5.GRNI Account Information");
            console.log("6.Bank Setup");
            console.log("7.Cash Setup");
            console.log("8.Operating Unit Setup");
            console.log("9.Chart of Account (Exclude FA)");
            console.log("10.Contact Setup");
            console.log("11.Block Setup (All)");
            console.log("12.Activity Setup");
            console.log("13.Company Setup");
            console.log("14.Gang Setup");
            console.log("15.Employee Setup/Staff Setup");
            console.log("16.Pay Code Setup");
            console.log("17.Mill Setup");
            console.log("18.Vehicle Setup");
            console.log("19.Attendance Setup");
            console.log("20.Position Setup");
            console.log("21.Division Setup");
            console.log("22.Store Setup");
            console.log("23.Item Category Setup");
            console.log("24.Item Setup");
            console.log("25.Plant Source Setup");
            console.log("26.Nursery Batch Setup");
            console.log("27.Plant Mate Setup");
            console.log("28.Department Setup");
            console.log("29.Additional Renumeration Setup");
            console.log("30.Tax Deduction Setup");
            console.log("31.Approval Level Setup");
            console.log("32.Account Category Setup");
            console.log("33.Account Setting");
            console.log("34.Tax Setup");
            console.log("35.Terrain Setup");
            console.log("36.Soil Type Setup");
            console.log("37.Uncultivated Area Setup");
            console.log("38.Budget");
            console.log("39.Chart of Account (All)");
            console.log("40.CC ID (All)");
            console.log("41.Chart of Account (Only FA)");
            console.log("42.CC ID (Only FA)");
            console.log("43.Field Setup");
            console.log("44.Inventory Setting");
            console.log("45.Produce Type Setup");
            console.log("46.Produce Setup");
            console.log("47.Sales Item Setup");
            console.log("48.Notification");
            
        }
        else {
            if (param1 >= 1 && param1 <= 48) {
                if ($rootScope.getConnectionState("main") === $.signalR.connectionState.connected) {
                    try {
                        let type = "";
                        type = param1 == 1 ? "uom" : type;
                        type = param1 == 2 ? "payterm" : type;
                        type = param1 == 3 ? "ccid" : type;
                        type = param1 == 4 ? "currinfo" : type;
                        type = param1 == 5 ? "grniacc" : type;
                        type = param1 == 6 ? "bank" : type;
                        type = param1 == 7 ? "cash" : type;
                        type = param1 == 8 ? "ou" : type;
                        type = param1 == 9 ? "coa" : type;
                        type = param1 == 10 ? "contact" : type;
                        type = param1 == 11 ? "block" : type;
                        type = param1 == 12 ? "activity" : type;
                        type = param1 == 13 ? "company" : type;
                        type = param1 == 14 ? "gang" : type;
                        type = param1 == 15 ? "empy" : type;
                        type = param1 == 16 ? "paycode" : type;
                        type = param1 == 17 ? "mill" : type;
                        type = param1 == 18 ? "vehicle" : type;
                        type = param1 == 19 ? "attdcode" : type;
                        type = param1 == 20 ? "post" : type;
                        type = param1 == 21 ? "div" : type;
                        type = param1 == 22 ? "store" : type;
                        type = param1 == 23 ? "itemcat" : type;
                        type = param1 == 24 ? "itemmas" : type;
                        type = param1 == 25 ? "plantsource" : type;
                        type = param1 == 26 ? "nurbatch" : type;
                        type = param1 == 27 ? "plantmate" : type;
                        type = param1 == 28 ? "dept" : type;
                        type = param1 == 29 ? "addrem" : type;
                        type = param1 == 30 ? "taxded" : type;
                        type = param1 == 31 ? "aprvlvl" : type;
                        type = param1 == 32 ? "acccat" : type;
                        type = param1 == 33 ? "fin" : type;
                        type = param1 == 34 ? "tax" : type;
                        type = param1 == 35 ? "terrain" : type;
                        type = param1 == 36 ? "soiltype" : type;
                        type = param1 == 37 ? "uncularea" : type;
                        type = param1 == 38 ? "bgtprep" : type;
                        type = param1 == 39 ? "facoa" : type;
                        type = param1 == 40 ? "faccid" : type;
                        type = param1 == 41 ? "onlyfacoa" : type;
                        type = param1 == 42 ? "onlyfaccid" : type;
                        type = param1 == 43 ? "field" : type;
                        type = param1 == 44 ? "stoconfg" : type;
                        type = param1 == 45 ? "vegetype" : type;
                        type = param1 == 46 ? "vege" : type;
                        type = param1 == 47 ? "salesitem" : type;
                        type = param1 == 48 ? "clientnotification" : type;
                       

                        $rootScope.forcedReloadServer(type, "");
                        console.log("%cRequest triggered successfully, might take some time to reflect depending on server load.", 'background:red;color:#fff');
                    }
                    catch (ex) {
                        console.log("%cRequest failed to trigger.", 'background:red;color:#fff');
                    }
                }
                else {
                    console.log("%cSignalR not connected, please reload and try again.", 'background:red;color:#fff');
                }
            }
            else {
                if (typeof clear === "function") {
                    clear();
                }

                _refreshCachedData();
            }
        }
    }

    //testing
    httpServiceFactory.broadcast = _broadcast;
    httpServiceFactory.devTest = _devTest;
    httpServiceFactory.speedTest = _speedTest;
    httpServiceFactory.refreshCachedData = _refreshCachedData;
    httpServiceFactory.updateDataSource = _updateDataSource;
    httpServiceFactory.parseStringToDecimal = httpBase._parseStringToDecimal;
    httpServiceFactory.extendKendoDatasource = _extendKendoDatasource;
    httpServiceFactory.getMessageNLabel = httpBase._getMessageNLabel;
    httpServiceFactory.getClientMenuNGrid = httpBase._getClientMenuNGrid;
    httpServiceFactory.forcedReloadClient = _forcedReloadClient;
    httpServiceFactory.logGeneralJsError = httpBase._logGeneralJsError;
    httpServiceFactory.postPerfDBLog = httpBase._postPerfDBLog;
    httpServiceFactory.getClientKey = httpBase._getClientKey;

    //requests
    httpServiceFactory.getReqExRate = _getReqExRate;
    httpServiceFactory.getReqDocStateChanged = _getReqDocStateChanged;
    httpServiceFactory.getReqYearEndMthStatus = _getReqYearEndMthStatus;
    httpServiceFactory.getReqGLHeader = _getReqGLHeader;

    //datasources
    httpServiceFactory.getDataExRate = _getDataExRate;
    httpServiceFactory.getPayeePayerAutoFillDs = _getPayeePayerAutoFillDs;
    httpServiceFactory.getOTVendNameAutoFillDs = _getOTVendNameAutoFillDs;
    httpServiceFactory.getBeneficiaryDs = _getBeneficiaryDs;

    httpServiceFactory._guid = httpBase._guid;
    httpServiceFactory._s4 = httpBase._s4;

    return httpServiceFactory;

    //testing testing 123 from ubuntu
}]);